import React, { memo, useState } from 'react'
import {
  TableCell,
  Paper,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CollapsibleTable from '../../../../components/TableBody/CollapsibleTable';
import AddEditService from '../../../MachineInfo/Machine Service Records/AddEditService';
import AddEditFuel from '../../../MachineInfo/FuelEntry/AddEditFuel';

const MachineMonthlyReport = (props: any) => {

  const { MachineReportData } = props;

  const [showModal, setShowModal] = useState(false);
  const [workLogState, setWorkLogState] = useState({
    id: '',
    work_item_id: '',
  });
  const [machineService, setMachineService] = useState({
    id: '',
    machine_id: "",
    site_id: "",
    machine_name: ""
  });
  const [machineFuel, setMachineFuel] = useState({
    id: '',
    machine_id: "",
    site_id: "",
  });

  const handleEditService = (id: string, machineId: string, siteId: string, machineName: string) => {
    setMachineService({ id: id, machine_id: machineId, site_id: siteId, machine_name: machineName });
    setShowModal(true);
  };

  const handleEditFuel = (id: string, machineId: string, site_id: string) => {
    setMachineFuel({ id: id, machine_id: machineId, site_id: site_id });
    setShowModal(true);
  };
  
  return (
    <div>
      <StyledEngineProvider>
        <TableContainer component={Paper} className="form-outers">
          <Table aria-label="collapsible table">
            <TableHead className='topheader'>
              <TableRow>
                <TableCell />
                <TableCell align="left">Machine Name</TableCell>
                <TableCell align="left">Total Collection</TableCell>
                <TableCell align="left">Total Fuel Consumption</TableCell>
                <TableCell align="left">Total Measurement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='tableInnerPanel'>
              {MachineReportData?.response?.map((row: any) => (
                <CollapsibleTable
                  selectedColumns={[
                    "machine_name",
                    "total_collection",
                    "total_fuel_consumption",
                    "total_measurement"
                  ]}
                  data={row}
                  subTableData={[
                    {
                      title: 'Work Log Data',
                      key: "work_log_data",
                      class: "work_log_detail",
                      data: [
                        { title: 'Machine Name', key: "machine_name" },
                        { title: 'Employee Name', key: "employee_name" },
                        { title: 'Work Type', key: "work_type" },
                        { title: 'Entry Date', key: "entry_date" },
                        { title: 'Trip Collection(Rupees)', key: "trip_collection" },
                        { title: 'Measurement', key: "measurement" },
                        { title: 'From Reading', key: "from_reading" },
                        { title: 'To Reading', key: "to_reading" },
                      ]
                    },
                    {
                      title: 'Service Record',
                      key: "service_record",
                      class: "service_record_detail",
                      data: [
                        { title: 'Machine Name', key: "machine_name" },
                        { title: 'Employee Name', key: "employee_name" },
                        { title: 'Service Type', key: "service_type" },
                        { title: 'Amount Expend(Rupees)', key: "amount" },
                        { title: 'Odometer', key: "odometer" },
                        { title: 'Date', key: "service_date" },
                      ],
                      isEdit: true,
                      editFn: (id: string, machineId: string, siteId: string, machineName: string) => { handleEditService(id, machineId, siteId, machineName) }
                    },
                    {
                      title: 'Fuel Consumption',
                      key: 'fuel_consumption',
                      class: "fuel_consumption_detail",
                      data: [
                        { title: 'Machine Name', key: "machine_name" },
                        { title: 'Employee Name', key: "employee_name" },
                        { title: 'Total Fuel Consumption', key: "total_fuel" },
                        { title: 'Date', key: "entry_date" },
                      ],
                      isEdit: true,
                      editFn: (id: string, machineId: string, siteId: string) => { handleEditFuel(id, machineId, siteId) }
                    }
                  ]}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showModal && machineService?.id !== '' &&
          <AddEditService
            id={machineService?.id}
            mchnData={[{ _id: machineService.id, machine_name: machineService?.machine_name }]}
            showModal={showModal}
            setShowModal={setShowModal}
            setMachineEntryEditId={() => { setMachineService({ id: "", machine_name: "", machine_id: "", site_id: "" }); props.refetchMachineReport(); }}
            paramId={machineService?.machine_id}
            siteId={machineService?.site_id}
          />
        }
        {showModal && machineFuel?.id !== '' &&
          <AddEditFuel
            id={machineFuel?.id}
            showModal={showModal}
            setShowModal={setShowModal}
            setFuelEntryEditId={() => { setMachineFuel({ id: "", machine_id: "", site_id: "" }); props.refetchMachineReport(); }}
            machineId={machineFuel?.machine_id}
            siteId={machineFuel?.site_id}
          />
        }
      </StyledEngineProvider>
    </div>
  )
}

export default memo(MachineMonthlyReport);