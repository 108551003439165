import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Paper,
  Snackbar,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  debounce,
} from "@mui/material";
import { TableBodyElement } from "../../../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  MachineType,
  Order,
  PageMeta,
  SortingData,
} from "../../../../types/CommonTypes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmBox from "../../../../components/ConfirmBox/ConfirmBox";
import { SearchElement } from "../../../../components/Search/Search";
import {
  useDeleteMachineInfoMutation,
  useGetSitesByIdQuery,
  useMachineInfoListQuery,
  useUpdateMachineStatusMutation,
} from "../../../../redux-services";
import SiteAddEditMachineInfo from "./SiteMachineAddEditModal";
import BackButtonListener from "../../../../components/BackButton/BackButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "machine_type",
    sort: true,
    label: "Machine Type",
  },
  {
    id: "company",
    sort: true,
    label: "Company",
  },
  {
    id: "machine_model",
    sort: false,
    label: "Model",
  },
  {
    id: "number_plate",
    sort: false,
    label: "Number Plate",
  },
  {
    id: "engine_run_hour",
    sort: true,
    label: "Engine Run Hour",
  },
  {
    id: "is_rented",
    sort: true,
    label: "Rented Machine",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "18%" },
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const SiteMachineInfo: FC<{ siteDetail: { id: string; name: string } }> = ({
  siteDetail,
}) => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  const userDetail = useSelector((state: RootState) => state.auth.user);
  let navigate = useNavigate();
  let query = useLocation();
  let { id: siteId } = useParams<string>();
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [open, setOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [machineInfoList, setMachineInfoList] =
    useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [activeMachineId, setActiveMachineId] = useState<string>("");
  const [machineStatus, setMachineStatus] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { data } = useMachineInfoListQuery({
    per_page: machineInfoList.per_page ?? rowsPerPage,
    page: query.state ?? machineInfoList.page,
    order: machineInfoList.order ?? order,
    order_by: machineInfoList.order_by ?? orderBy,
    searchText: machineInfoList.searchText ?? search,
    id: siteId,
    pagination: true,
  });

  const { data: getData } = useGetSitesByIdQuery(siteId);
  useEffect(() => {
    if (getData?.status === 200) {
      let data = getData?.response;
      setIsActive(data.is_active);
      setIsButtonDisabled(data.is_deactivate);
    }
  }, [getData]);

  const [deleteMachineInfo] = useDeleteMachineInfoMutation();
  const [updateActiveStatus] = useUpdateMachineStatusMutation();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setMachineInfoList({
      ...machineInfoList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setMachineInfoList({ ...machineInfoList, page: newPage + 1 });

    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setMachineInfoList({
      ...machineInfoList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setMachineInfoList({
        page: 1,
        per_page: machineInfoList.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, []);

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteMachineInfo(Id);
    } else {
      setIsClose(res);
    }
  };
  const handleModel = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);
  const handleToggleActive = (id: string) => {
    const machine = data?.response.data.find(
      (machine: MachineType) => machine._id === id
    );
    if (machine) {
      setActiveMachineId(id);
      setIsConfirmationOpen(true);
      setMachineStatus(machine.is_active);
    }
  };
  const handleConfirmationResponse = (confirmed: boolean) => {
    if (confirmed) {
      const newStatus = !machineStatus;
      updateActiveStatus({ id: activeMachineId, status: newStatus });
    }
    setActiveMachineId("");
    setIsConfirmationOpen(false);
  };
  const handleShowMessage = () => {
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };
  const isActivefn = (id: any) => {
    if (isButtonDisabled) {
      alert("Cannot toggle active status (site is deactivated)");
      return;
    }
    handleToggleActive(id);
  };
  const deleteFn = (id: string) => {
    if (isButtonDisabled) {
      alert("Completed site cannot be deleted");
      return;
    }
    setIsClose(true);
    setId(id);
  };
  return (
    <>
      <BackButtonListener />
      <Paper className="table-outers mb-2">
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 40%" }}
            variant="h5"
            id="tableTitle"
            component="div"
            className="inner-headings">
            Machinery List
          </Typography>
          <div className="flex-boxinner">
            <Typography sx={{ flex: "1 1 auto" }}>
              <SearchElement
                searchFn={searchHandler}
                searchTag={"Search Machinery..."}
              />
            </Typography>
            {isButtonDisabled ? null : (
              <>
                <Tooltip
                  title="View Machine Report"
                  arrow
                  className="me-1 mt-1">
                  <Button
                    className="secondary-btn"
                    onClick={() =>
                      navigate("/admin/machine/report", { state: { siteId } })
                    }
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}>
                    Machine Live Report
                  </Button>
                </Tooltip>
              </>
            )}
            <Snackbar
              open={snackbarOpen && !isActive}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
              message="Please activate this site to add Machine."
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              style={{ marginTop: "140px" }}
            />
          </div>
        </Toolbar>
        <StyledEngineProvider>
          <TableContainer component={Paper} className="table-outers">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              className="table-outers">
              <TableHead>
                <TableRow>
                  <TableHeadElement
                    setHead={tableColumns ? tableColumns : []}
                    order={order}
                    orderBy={orderBy}
                    sortFn={(event: any, id: string) =>
                      createSortHandler(event, id as keyof SortingData)
                    }
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableBodyElement
                  selectedColumns={[
                    "machine_type",
                    "company",
                    "machine_model",
                    "number_plate",
                    "engine_run_hour",
                    "is_rented",
                  ]}
                  setData={data?.response.data ? data?.response.data : []}
                  deleteFn={userDetail?.role_type === 0 ? undefined : deleteFn}
                  viewMachineFn={(id: string) =>
                    navigate(`/admin/site/${siteId}/machine/${id}`, {
                      state: page,
                    })
                  }
                  viewMachineHistoryFn={(id: string) => {
                    navigate(`/admin/site/machine/${id}/history/${siteId}`, {
                      state: { page },
                    });
                  }}
                  navigationFn={(id)=>{
                    navigate(`/admin/site/${siteId}/machine/${id}`)
                  }}
                  viewServiceHistoryFunction={(id: string) => {
                    const currentUrl = query.pathname + query.search;
                    navigate(`/admin/site/machine/${id}/servicehistory`, {
                      state: { from: currentUrl, page: page },
                    });
                  }}
                  fuelFn={userDetail?.role_type === 0 ? undefined : (id: string) => {
                    navigate(`/admin/site/${siteId}/machine/${id}`, {
                      state: page,
                    });
                  }}
                  isActivefn={userDetail?.role_type === 0 ? undefined : (id) => isActivefn(id)}
                  pageData={{
                    limit:
                      rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: data?.response.page as number,
                  }}
                />
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={data?.response.total ?? 0}
              page={data?.response.page ?? 0}
              rowsPerPageOptions={[20, 50, 100]}
              onPageChange={handleChangePage}
              rowsPerPage={
                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </StyledEngineProvider>
      </Paper>
      <ConfirmBox
        title={`Machine ${
          machineStatus ? "Deactivate" : "Activate"
        } Confirmation`}
        message={
          activeMachineId
            ? `Are you sure you want to ${
                machineStatus ? "deactivate" : "activate"
              } this machine?`
            : ""
        }
        open={isConfirmationOpen}
        onClose={handleConfirmationResponse}
      />
      <ConfirmBox
        title="Machinery Delete Confirmation"
        message="Are you sure, you want to delete this Machinery?"
        open={isClose}
        onClose={confirmBoxClose}
      />
      {open && isActive && (
        <SiteAddEditMachineInfo
          open={open}
          handleModel={handleModel}
          siteInfo={siteDetail}
          id={editId}
        />
      )}
    </>
  );
};

export default memo(SiteMachineInfo);
