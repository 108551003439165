import React, { FC, useState, useEffect } from 'react';
import { TextField, Button, Grid, FormControlLabel, Checkbox, Box, Modal, Backdrop, Fade, Typography, Divider, Container, TableContainer, Table, TableRow, Paper, TableHead, TableBody } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from '../../../redux-services';
import { useCreateMachineDocumentTypeMutation, useMachineDocumentTypeListQuery, useDeleteMachineDocTypeMutation, useEditMachineDocTypeMutation, } from '../../../redux-services/api/MachineDocumentTypeApi';
import { TableBodyElement } from '../../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../../components/TableBody/TableHeadElement';
import { RootState } from '../../../store/store';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../types/CommonTypes';
import ConfirmBox from '../../../components/ConfirmBox/ConfirmBox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Error from "../../../components/ErrorBox/Error";

const tableColumns: ColumnsType[] = [
  {
    id: "document_type",
    sort: true,
    label: "Document Name",
  },
  {
    id: "createdAt",
    sort: true,
    label: "Created At",
  },

  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "15%" },
  },
];
export interface FormData {
  document_type: string;
  expiry_date: boolean;
  insurance_amount: boolean;
  images: boolean;
}

interface FormErrors {
  document_type?: string;
}

const AddMachineDocumentType: FC<{
  documentOpen: boolean;
  handleDocumentModel: any;
}> = ({ documentOpen, handleDocumentModel }) => {
  const initialStatee: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };

  const initialState: FormData = {
    document_type: "",
    expiry_date: false,
    insurance_amount: false,
    images: true,
  };

  const dispatch = useDispatch();
  const initialOrder: Order = process.env.REACT_APP_ORDER as Order;
  const [orgId, setOrgId] = useState("");
  const userLoginDetail = useSelector((state: RootState) => state.auth);

  const [formData, setFormData] = useState<FormData>(initialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder);
  const [shiftLogsList, setShiftLogsList] = useState<PageMeta>(initialStatee);
  const [machineData, setMachineData] = useState<string[]>([]);

  const [createMachineDocumentType, result] =
    useCreateMachineDocumentTypeMutation();
  const [editMachineDocumentType, editResult] = useEditMachineDocTypeMutation();


  const { data: machineDocTypeData } = useMachineDocumentTypeListQuery(orgId, {
    skip: !orgId,
  });
 
   
  console.log(machineDocTypeData, "dataa")
  const [machineDocumentEditId, setMachineDocumentEditId] =
    useState<string>("");
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");

  const [deleteMachineDoc] = useDeleteMachineDocTypeMutation();

  useEffect(() => {
    if (userLoginDetail?.user?.organization_id) {
      setOrgId(userLoginDetail?.user?.organization_id);
    } else {
      setOrgId(localStorage.getItem('orgId') ?? '');
    }
  }, [userLoginDetail?.user?.organization_id])

  useEffect(() => {
    if (machineDocTypeData?.status === 200) {
      const machineData = machineDocTypeData?.response?.map((doc: any) => doc.document_type)
      setMachineData(machineData);
      let data: any = machineDocTypeData?.response;
      if (machineDocumentEditId) {
        const documentToEdit = data.find((doc: { _id: string; }) => doc._id === machineDocumentEditId);
        if (documentToEdit) {
          setFormData({
            document_type: documentToEdit?.document_type ?? "",
            images: documentToEdit?.images ?? true,
            expiry_date: documentToEdit?.expiry_date ?? false,
            insurance_amount: documentToEdit?.insurance_amount ?? false
          });
        }
        return () => {
          dispatch(setError([]));
        };
      }
    }
  }, [machineDocumentEditId, machineDocTypeData?.status, dispatch]);

  useEffect(() => {
    if (result.isSuccess && result.data.status === 200) {
      setFormData({ ...initialState });
    }

    if (editResult.isSuccess && editResult.data.status === 200) {
      setMachineDocumentEditId("");
      setFormData({ ...initialState });
    }
  }, [result, editResult]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const checkError = validate(formData);
    if (Object.values(checkError)?.length > 0) {
      setFormErrors(checkError);
    } else {
      setFormErrors({});
      dispatch(setError([]));
      const formattedData = {
        document_type: formData.document_type,
        expiry_date: formData.expiry_date,
        insurance_amount: formData.insurance_amount,
        images: formData.images,
      };

      try {
        if (machineDocumentEditId) {
          await editMachineDocumentType({ id: machineDocumentEditId, data: formattedData }).unwrap();
        } else {
          await createMachineDocumentType(formattedData).unwrap();
        }

      } catch (error) {
        dispatch(setError(['An error occurred']));
      }
    }
  };
  const mappedData = machineDocTypeData?.response?.map((doc: {
    createdAt: any; _id: string; document_name: any; document_type: any;
  }) => ({
    document_type: doc?.document_type,
    createdAt: doc?.createdAt,
    _id: doc._id ?? "",
  })
  ) || [];
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setShiftLogsList({ ...shiftLogsList, order: newOrder, order_by: property });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };
  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteMachineDoc(Id);
    }
  };

  const validateDocumentType = (value: string): string => {
    if (machineData.includes(value)) {
      return "Document type already exists, please enter a new one.";
    }
    return "";
  };

  const validate = (formData: FormData) => {
    const errors: FormErrors = {};
    const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]+/;

    if (!formData.document_type.trim()) {
      errors.document_type = "Document Type is required!";
    } else if (regex.test(formData.document_type)) {
      errors.document_type = "Document Type cannot contain special characters!";
    }


    return errors;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value, checked, type } = e.target;

    if (e.target.name === "document_type" && value.length > 0) {
      value = value[0]?.toUpperCase() + value.slice(1);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value.trimStart(),
    }));

    if (name === "document_type") {
      const documentTypeError = validateDocumentType(value);
      const formErrors = validate({ ...formData, document_type: value });
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        document_type: documentTypeError || formErrors.document_type,
      }));
    }
  };


  return (
    <Container maxWidth={false} className="p-0">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={documentOpen}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={documentOpen}>
          <Box sx={style} className="form-outers karuModal">
            <Typography
              id="transition-modal-title"
              variant="h5"
              className="inner-headings"
              component="h2"
            >
              {machineDocumentEditId ? "Edit Document Type" : "Add Document Type"}
            </Typography>
            <HighlightOffIcon
              className="closeicon"
              onClick={() => handleDocumentModel(false)}
            />
            <Box className="modalBody">
              <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      id="document_type"
                      label="Document Type"
                      required
                      name="document_type"
                      type="string"
                      size="small"
                      autoComplete="document_type"
                      value={formData.document_type}
                      onChange={handleChange}
                      error={!!formErrors.document_type}
                      helperText={formErrors.document_type}
                    />
                    <Error current_key="document_type" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.images}
                          onChange={handleChange}
                          name="images"
                          disabled
                        />
                      }
                      label="Images"
                      sx={{ color: "white" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.insurance_amount}
                          onChange={handleChange}
                          name="insurance_amount"
                        />
                      }
                      label="Amount"
                      sx={{ color: "white" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.expiry_date}
                          onChange={handleChange}
                          name="expiry_date"
                        />
                      }
                      label="Expiry Date"
                      sx={{ color: "white" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="save-btn"
                      style={{ marginRight: "10px" }}
                      disabled={Object.values(formErrors).some(
                        (error) => !!error
                      )}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="close-btn"
                      onClick={() => handleDocumentModel(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Divider sx={{ marginTop: 5, marginBottom: 3 }} />
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                  className="table-outers"
                >
                  <TableHead>
                    <TableRow>
                      <TableHeadElement
                        setHead={tableColumns ? tableColumns : []}
                        order={order}
                        orderBy={orderBy}
                        sortFn={(event, id: string) =>
                          createSortHandler(event, id as keyof SortingData)
                        }
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyElement
                      selectedColumns={["document_type", "createdAt"]}
                      setData={mappedData}
                      showSerialNumber={false}
                      editFn={(id) => {
                        setMachineDocumentEditId(id);
                      }}
                      deleteFn={(id) => {
                        setIsClose(true);
                        setId(id);
                      }}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
              <ConfirmBox
                title="Machine Document Delete Confirmation"
                message="Are you sure, you want to delete this Machine Document?"
                open={isClose}
                onClose={confirmBoxClose}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default AddMachineDocumentType;
