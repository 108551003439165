import React, { useMemo, useCallback, useState } from "react";
import {
  Button,
  Paper,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  Autocomplete,
  TextField,
  debounce,
  Box,
  Modal,
} from "@mui/material";
import { TableBodyElement } from "../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  MachineType,
  Order,
  PageMeta,
  SortingData,
} from "../../types/CommonTypes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import { SearchElement } from "../../components/Search/Search";
import {
  useDeleteMachineInfoMutation,
  useMachineInfoListQuery,
  useUpdateMachineSiteMutation,
  useSitesListByOrgIdQuery,
  useUpdateMachineStatusMutation,
} from "../../redux-services";
import AddMachineType from "./MachineType";
import AddMachineDocumentType from "./MachineDocumentType";
import arrowImage from "../../assests/img/arrow.svg";

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "machine_type",
    sort: true,
    label: "Machine Type",
  },
  {
    id: "site_name",
    sort: true,
    label: "Site Name",
  },
  {
    id: "company",
    sort: true,
    label: "Company",
  },
  {
    id: "machine_model",
    sort: false,
    label: "Model",
  },
  {
    id: "number_plate",
    sort: false,
    label: "Number Plate",
  },
  {
    id: "is_rented",
    sort: true,
    label: "Rented Machine",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "18%" },
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const MachineInfo = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  let navigate = useNavigate();
  let query = useLocation();
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [open, setOpen] = useState<boolean>(false);
  const [documentOpen, setDocumentOpen] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [activeMachineId, setActiveMachineId] = useState<string>("");
  const [machineStatus, setMachineStatus] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [machineInfoList, setMachineInfoList] =
    useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");
  const [isSitePopupOpen, setIsSitePopupOpen] = useState<boolean>(false);
  const [selectedSite, setSelectedSite] = useState<{
    siteId: string;
    siteName: string;
  } | null>(null);

  const { data } = useMachineInfoListQuery({
    per_page: machineInfoList.per_page ?? rowsPerPage,
    page: query.state ?? machineInfoList.page,
    order: machineInfoList.order ?? order,
    order_by: machineInfoList.order_by ?? orderBy,
    searchText: machineInfoList.searchText ?? search,
    pagination: true,
  });

  const organizationId = localStorage.getItem("orgId");

  const { data: siteData } = useSitesListByOrgIdQuery({
    order: "asc",
    order_by: "name",
    organization_id: organizationId,
  });
  const handleMoveToSite = (machineId: string) => {
    setActiveMachineId(machineId);
    setIsSitePopupOpen(true);
  };

  const handleSiteSelection = (siteId: string, siteName: string) => {
    setSelectedSite({ siteId, siteName });
  };
  const [updateMachineSite] = useUpdateMachineSiteMutation();
  const handleMoveConfirmation = async (confirmed: boolean) => {
    if (confirmed && selectedSite && activeMachineId) {
      try {
        const response = await updateMachineSite({
          id: activeMachineId,
          site_id: selectedSite.siteId,
        }).unwrap();
      } catch (error) {
        console.error("Failed to update machine site", error);
      }
    }

    setIsSitePopupOpen(false);
    setIsConfirmationOpen(false);
    setSelectedSite(null);
  };

  const [deleteMachineInfo] = useDeleteMachineInfoMutation();
  const [updateActiveStatus] = useUpdateMachineStatusMutation();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setMachineInfoList({
      ...machineInfoList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setMachineInfoList({ ...machineInfoList, page: newPage + 1 });

    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setMachineInfoList({
      ...machineInfoList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setMachineInfoList({
        page: 1,
        per_page: machineInfoList.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, []);

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteMachineInfo(Id);
    } else {
      setIsClose(res);
    }
  };
  const handleToggleActive = (id: string) => {
    const machine = data?.response.data.find(
      (machine: MachineType) => machine._id === id
    );
    if (machine) {
      setActiveMachineId(id);
      setIsConfirmationOpen(true);
      setMachineStatus(machine.is_active);
    }
  };
  const handleConfirmationResponse = (confirmed: boolean) => {
    if (confirmed) {
      const newStatus = !machineStatus;
      updateActiveStatus({ id: activeMachineId, status: newStatus });
    }
    setActiveMachineId("");
    setIsConfirmationOpen(false);
  };

  const handleModel = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);

  const handleDocumentModel = useCallback((isOpen: boolean) => {
    setDocumentOpen(isOpen);
  }, []);
  const breadcrumbs = [{ name: "Machinery Lists", to: "/admin/machine" }];

  return (
    <div>
      <Paper className="table-outers mb-2">
        <Toolbar className="machinepagehead">
          <div
            className="breadcrumbs"
            style={{ display: "flex", alignItems: "center" }}>
            {breadcrumbs.map((crumb, index) => (
              <React.Fragment key={index}>
                {index > 0 && (
                  <img
                    src={arrowImage}
                    alt="arrow"
                    style={{ margin: "0 8px", verticalAlign: "middle" }}
                  />
                )}
                {index === breadcrumbs.length - 1 ? (
                  <Typography
                    variant="body2"
                    component="span"
                    style={{ color: "white" }}>
                    {crumb.name}
                  </Typography>
                ) : (
                  <Link
                    to={crumb.to}
                    className="breadcrumb-link"
                    style={{ color: "white" }}>
                    {crumb.name}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </div>
        </Toolbar>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 20%" }}
            variant="h5"
            id="tableTitle"
            component="div"
            className="inner-headings">
            Machinery List
          </Typography>
          <div className="flex-boxinner">
            <Typography sx={{ flex: "1 1 auto" }}>
              <SearchElement
                searchFn={searchHandler}
                searchTag={"Search Machinery..."}
              />
            </Typography>
            <Tooltip title="Add Machine Info" arrow className="me-1 mt-1">
              <Button
                className="secondary-btn"
                onClick={() => navigate("/admin/machine/add")}
                type="submit"
                variant="contained"
                color="primary">
                Add Machinery
              </Button>
            </Tooltip>
            <Tooltip title="View Machine Report" arrow className="me-1 mt-1">
              <Button
                className="secondary-btn"
                onClick={() => {
                  const currentUrl = query.pathname + query.search;
                  navigate("/admin/machine/report", {
                    state: { from: currentUrl },
                  });
                }}
                type="submit"
                variant="contained"
                color="primary">
                Machine Live Report
              </Button>
            </Tooltip>
            <Tooltip title="Add Machine Info" arrow className="me-1 mt-1">
              <Button
                className="secondary-btn"
                onClick={() => setOpen(true)}
                type="submit"
                variant="contained"
                color="primary">
                Add Machine Type
              </Button>
            </Tooltip>
            <Tooltip title="Add Machine Document" arrow className="me-1 mt-1">
              <Button
                className="secondary-btn"
                onClick={() => setDocumentOpen(true)}
                type="submit"
                variant="contained"
                color="primary">
                Add Document Type
              </Button>
            </Tooltip>
          </div>
        </Toolbar>
        <StyledEngineProvider>
          <TableContainer component={Paper} className="table-outers">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              className="table-outers">
              <TableHead>
                <TableRow>
                  <TableHeadElement
                    setHead={tableColumns ? tableColumns : []}
                    order={order}
                    orderBy={orderBy}
                    sortFn={(event, id: string) =>
                      createSortHandler(event, id as keyof SortingData)
                    }
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableBodyElement
                  selectedColumns={[
                    "machine_type",
                    "site_name",
                    "company",
                    "machine_model",
                    "number_plate",
                    "is_rented",
                  ]}
                  setData={data?.response.data ? data?.response.data : []}
                  editFn={(id) =>
                    navigate(`/admin/machine/edit/${id}`, {
                      state: page,
                    })
                  }
                  deleteFn={(id) => {
                    setIsClose(true);
                    setId(id);
                  }}
                  moveFn={(id) => handleMoveToSite(id)}
                  viewMachineFn={(id: string, siteId: string) =>
                    navigate(`/admin/machine/${id}`, {
                      state: page,
                    })
                  }
                  navigationFn={(id) => {
                    navigate(`/admin/machine/${id}`);
                  }}
                  viewMachineHistoryFn={(id: string) => {
                    const currentUrl = query.pathname + query.search;
                    navigate(`/admin/site/machine/${id}/history`, {
                      state: { from: currentUrl, page: page },
                    });
                  }}
                  viewServiceHistoryFunction={(id: string) => {
                    const currentUrl = query.pathname + query.search;
                    navigate(`/admin/site/machine/${id}/servicehistory`, {
                      state: { from: currentUrl, page: page },
                    });
                  }}
                  isActivefn={(id) => handleToggleActive(id)}
                  pageData={{
                    limit:
                      rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: data?.response.page as number,
                  }}
                />
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={data?.response.total ?? 0}
              page={data?.response.page ?? 0}
              rowsPerPageOptions={[20, 50, 100]}
              onPageChange={handleChangePage}
              rowsPerPage={
                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </StyledEngineProvider>
      </Paper>
      <ConfirmBox
        title={`Machine ${machineStatus ? "Deactivate" : "Activate"
          } Confirmation`}
        message={
          activeMachineId
            ? `Are you sure you want to ${machineStatus ? "deactivate" : "activate"
            } this machine?`
            : ""
        }
        open={isConfirmationOpen}
        onClose={handleConfirmationResponse}
      />
      <ConfirmBox
        title="Machinery Delete Confirmation"
        message="Are you sure, you want to delete this Machinery?"
        open={isClose}
        onClose={confirmBoxClose}
      />
      {open && <AddMachineType open={open} handleModel={handleModel} />}
      {documentOpen && (
        <AddMachineDocumentType
          documentOpen={documentOpen}
          handleDocumentModel={handleDocumentModel}
        />
      )}

      {isSitePopupOpen && (
        <Modal
          open={isSitePopupOpen}
          onClose={() => setIsSitePopupOpen(false)}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description">
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "#0F1E31",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
              color: "#ffffff",
            }}>
            <Typography
              id="select-site-modal"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontWeight: 600, fontSize: "1.5rem" }}>
              Select a Site
            </Typography>

            <Typography
              sx={{
                marginBottom: "12px",
                color: "#b0b0b0",
                fontSize: "0.875rem",
              }}>
              Please select a site to move the machine:
            </Typography>

            <Autocomplete
              id="site-select"
              options={
                siteData?.response?.data.filter(
                  (site: any) => site.is_deactivate !== true
                ) || []
              }
              getOptionLabel={(option) => option.name}
              value={
                selectedSite
                  ? { _id: selectedSite.siteId, name: selectedSite.siteName }
                  : undefined
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  handleSiteSelection(newValue._id, newValue.name);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option._id === value?._id
              }
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select site"
                  fullWidth
                  variant="outlined"
                  className="raju-clas"
                />
              )}
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button
                onClick={() => setIsSitePopupOpen(false)}
                className="close-btn"
                style={{ marginRight: "10px" }}>
                Cancel
              </Button>
              <Button
                onClick={() => setIsConfirmationOpen(true)}
                className="save-btn"
                disabled={!selectedSite}>
                Move
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      {isConfirmationOpen && selectedSite && (
        <ConfirmBox
          title="Move Machine Confirmation"
          message={`Are you sure you want to move this machine to ${selectedSite.siteName} site ?`}
          open={isConfirmationOpen}
          onClose={handleMoveConfirmation}
        />
      )}
    </div>
  );
};

export default MachineInfo;
