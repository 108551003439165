import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Modal,
  Fade,
  TextField,
  Typography,
  // Typography,
} from "@mui/material";
import React, { FC, FormEvent, useEffect, useState } from "react";
import {
  useCreateChartMutation,
  useGetChartQuery,
  useUpdateChartMutation,
} from "../../../redux-services/api/RateChartApi";
import Backdrop from "@mui/material/Backdrop";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import EditIcon from "@mui/icons-material/Edit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export interface UserState {
  road_side_fixed_rate: string;
  road_side_additional_rate: string;
  bore_area_fixed_rate: string;
  bore_area_additional_rate: string;
  distance_limit: string;
}

const RateChartCard: FC<{
  open: boolean;
  handleRateCardModal: any;
  RateData?: UserState;
  isEdit?: boolean;
  siteDetail: { id: string; name: string };
  onRateDataUpdate?: any;
}> = ({
  open,
  handleRateCardModal,
  // RateData,
  // isEdit,
  siteDetail,
  onRateDataUpdate,
}) => {
  const initialState: UserState = {
    road_side_fixed_rate: "",
    road_side_additional_rate: "",
    bore_area_fixed_rate: "",
    bore_area_additional_rate: "",
    distance_limit: "",
  };

  const [formData, setFormData] = useState<UserState>(initialState);
  // const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState<UserState>(initialState);
  const [formErrors, setFormErrors] = useState<Record<string, string | null>>(
    {}
  );

  const { data: chartData } = useGetChartQuery(siteDetail.id, {
    skip: !siteDetail.id,
  });
  const [createChart] = useCreateChartMutation();
  const [updateChart] = useUpdateChartMutation();

  useEffect(() => {
    if (chartData?.status === 200 && chartData?.response) {
      // const status = chartData?.response?.is_deactivated;
      // setIsButtonDisabled(status);
      const fetchedData = {
        road_side_fixed_rate: chartData.response.road_side_fixed_rate ?? 0,
        road_side_additional_rate:
          chartData.response.road_side_additional_rate ?? 0,
        bore_area_fixed_rate: chartData.response.bore_area_fixed_rate ?? 0,
        bore_area_additional_rate:
          chartData.response.bore_area_additional_rate ?? 0,
        distance_limit: chartData.response.distance_limit ?? 0,
      };
      setFormData(fetchedData);
      setOriginalData(fetchedData);
    }
  }, [chartData]);

  const validateForm = () => {
    const errors: Record<string, string | null> = {};

    if (formData.distance_limit === "")
      errors.distance_limit = "Distance Limit cannot be empty.";
    if (formData.road_side_fixed_rate === "")
      errors.road_side_fixed_rate = "Road Side Fixed Rate cannot be empty.";
    if (formData.road_side_additional_rate === "")
      errors.road_side_additional_rate =
        "Road Side Additional Rate cannot be empty.";
    if (formData.bore_area_fixed_rate === "")
      errors.bore_area_fixed_rate = "Bore Area Fixed Rate cannot be empty.";
    if (formData.bore_area_additional_rate === "")
      errors.bore_area_additional_rate =
        "Bore Area Additional Rate cannot be empty.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const data = {
      site_id: siteDetail.id,
      road_side_fixed_rate: Number(formData.road_side_fixed_rate) || 0,
      road_side_additional_rate:
        Number(formData.road_side_additional_rate) || 0,
      bore_area_fixed_rate: Number(formData.bore_area_fixed_rate) || 0,
      bore_area_additional_rate:
        Number(formData.bore_area_additional_rate) || 0,
      distance_limit: Number(formData.distance_limit) || 0,
    };

    try {
      if (chartData?.status === 200) {
        await updateChart({ siteId: siteDetail.id, data });
      } else {
        await createChart(data);
      }

      onRateDataUpdate(data);
      handleRateCardModal(false);
    } catch (error) {
      console.error("Error updating/creating chart:", error);
    }
  };

  const handleReset = () => {
    setFormData(initialState);
    setFormErrors({});
  };

  const handleCancel = () => {
    handleRateCardModal(false);
    setFormData(originalData);
    setFormErrors({});
    // setIsEditMode(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = value === "" ? "" : Number(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    if (value !== "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}>
      <Fade in={open}>
        <Box sx={style} className="form-outers karuModal">
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              id="transition-modal-title"
              variant="h5"
              className="inner-headings"
              component="h2">
              {"Rate Chart"}
            </Typography>

            <HighlightOffIcon className="closeicon" onClick={handleCancel} />
          </Box>
          <CardContent>
            <Divider sx={{ marginBottom: 3 }} />
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField
                    required
                    fullWidth
                    id="distance_limit"
                    label="Distance Limit"
                    name="distance_limit"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.distance_limit}
                    onChange={handleChange}
                    error={!!formErrors.distance_limit}
                    helperText={formErrors.distance_limit}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    id="road_side_fixed_rate"
                    label="Road Side Fixed Rate"
                    name="road_side_fixed_rate"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.road_side_fixed_rate}
                    onChange={handleChange}
                    error={!!formErrors.road_side_fixed_rate}
                    helperText={formErrors.road_side_fixed_rate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    id="road_side_additional_rate"
                    label={`Road Side Additional Rate ${
                      formData.distance_limit !== ""
                        ? "After " + formData.distance_limit + "Km"
                        : ""
                    } (per/km) `}
                    name="road_side_additional_rate"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.road_side_additional_rate}
                    onChange={handleChange}
                    error={!!formErrors.road_side_additional_rate}
                    helperText={formErrors.road_side_additional_rate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    id="bore_area_fixed_rate"
                    label="Bore Area Fixed Rate"
                    name="bore_area_fixed_rate"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.bore_area_fixed_rate}
                    onChange={handleChange}
                    error={!!formErrors.bore_area_fixed_rate}
                    helperText={formErrors.bore_area_fixed_rate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    id="bore_area_additional_rate"
                    label={`Bore Area Additional Rate ${
                      formData.distance_limit !== ""
                        ? "After " + formData.distance_limit + "Km"
                        : ""
                    } (per/km) `}
                    name="bore_area_additional_rate"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.bore_area_additional_rate}
                    onChange={handleChange}
                    error={!!formErrors.bore_area_additional_rate}
                    helperText={formErrors.bore_area_additional_rate}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="save-btn"
                    // disabled={isButtonDisabled}
                    style={{ marginRight: "10px", marginBottom: "20px" }}>
                    {chartData && Object.keys(chartData).length > 0
                      ? "Submit"
                      : "Create"}
                  </Button>
                  <Button
                    variant="contained"
                    className="close-btn"
                    color="secondary"
                    onClick={handleReset}
                    // disabled={isButtonDisabled}
                    style={{ marginRight: "10px", marginBottom: "20px" }}>
                    Reset
                  </Button>
                  <Button
                    variant="outlined"
                    className="close-btn"
                    onClick={handleCancel}
                    style={{ marginRight: "10px", marginBottom: "20px" }}>
                    {" "}
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
          {/* </Card> */}
          {/* </Typography> */}
        </Box>
      </Fade>
    </Modal>
  );
};

export default RateChartCard;
