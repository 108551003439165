import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { TableBodyElement } from "../../../components/TableBody/TableBodyElement";
import { RootState } from "../../../store/store";

import { TableHeadElement } from "../../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../../types/CommonTypes";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";
import { useGetMachineInfoByIdQuery } from "../../../redux-services";
import AddEditDocument from "./AddEditDocument";
import {
  useDeleteMachineDocMutation,
  useGetAllMachineDocsQuery,
  useGetDocumentInfoByIdQuery,
} from "../../../redux-services/api/MachineDocApi";
import CloseIcon from '@mui/icons-material/Close';
import { useMachineDocumentTypeListQuery } from "../../../redux-services/api/MachineDocumentTypeApi";
import { useSelector } from "react-redux";

interface PreviousImage {
  path: string;
  fullUrl: string;
  expiry_date: number;
  insurance_amount: number;
  encodedUrl: [];
}

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "document_type",
    sort: true,
    label: "Document Type",
  },
  {
    id: "insurance_amount",
    sort: true,
    label: "Amount"
  },
  {
    id: "expiry_date",
    sort: true,
    label: "Expiry Date"
  },
  {
    id: "createdAt",
    sort: true,
    label: "Created At",
  },

  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "15%" },
  },
];

const initialOrder: Order = process.env.REACT_APP_ORDER as Order;

const MachineDocument = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };

  const { id } = useParams<string>();

  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder);
  const [shiftLogsList, setShiftLogsList] = useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [machineDocumentEditId, setMachineDocumentEditId] = useState<string>("");
  const [viewDocumentEditId, setViewDocumentEditId] = useState<string>("");
  const [viewPreviousDocumentEditId, setViewPreviousDocumentId] = useState<string>("");
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [previousImageModalOpen, setPreviousImageModalOpen] = useState(false);
  const [Id, setId] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isSiteActive, setIsSiteActive] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [previousImages, setPreviousImages] = useState<PreviousImage[]>([]);
  const [createdDate, setCreatedDate] = useState<number | null>(null);
  const [orgId, setOrgId] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [previousImageUrl, setPreviousImageUrl] = useState<string>('');
  const { data: getData, refetch } = useGetMachineInfoByIdQuery(id, {
    skip: !id,
  });

  useEffect(() => {
    refetch();
  }, [id, refetch]);
  const userLoginDetail = useSelector((state: RootState) => state.auth);

  const { data: DocumentData } = useGetDocumentInfoByIdQuery(viewDocumentEditId, {
    skip: !viewDocumentEditId,
  });
  useEffect(() => {
    if (DocumentData) {
      setImageUrl(DocumentData?.response?.encodedUrl[0])
      setPreviousImageUrl(DocumentData?.response?.prevEncodedUrl[0])
    }
  })
  useEffect(() => {
    if (getData?.status === 200) {
      const getDataResponse = getData?.response;
      let data = getDataResponse;
      let deactivateStatus = data?.siteData[0]?.is_deactivate
      setIsButtonDisabled(deactivateStatus);
      setIsActive(data.is_active);
      setIsSiteActive(data?.siteData[0]?.is_active)
    }
  }, [getData]);
  useEffect(() => {
    if (userLoginDetail?.user?.organization_id) {
      setOrgId(userLoginDetail?.user?.organization_id);
    } else {
      setOrgId(localStorage.getItem("orgId") ?? "");
    }
  }, [userLoginDetail?.user?.organization_id]);
  const [deleteMachineDoc] = useDeleteMachineDocMutation();

  const { data } = useGetAllMachineDocsQuery(id, {
    skip: !id,
  });
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setShiftLogsList({ ...shiftLogsList, order: newOrder, order_by: property });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteMachineDoc(Id);
    }
  };

  const handleViewDocument = (id: string) => {
    const document = data?.response?.data.find((doc: any) => doc._id === id);
    setCreatedDate(document.createdAt);
    setPreviousImages(document.previous_images || []);
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const viewDownloadFn = (downloadId: string) => {
    setViewDocumentEditId(downloadId)
    setImageModalOpen(true);
  };
  const handleModalClose = () => {
    setImageModalOpen(false);
  };
  const viewPreviousDownloadFn = () => {
    setPreviousImageModalOpen(true);
  };
  const handlePreviousModalClose = () => {
    setPreviousImageModalOpen(false);
  };
  const handleViewDownload = () => {
    const documentIndex = data?.response?.data.findIndex(
      (doc: { _id: any }) => doc._id === viewDocumentEditId
    );
    if (documentIndex !== -1) {
      const url = data?.response.encodedUrl[documentIndex];
      window.open(url, "_blank");
      handleModalClose()
    } else {
      console.log("Document not found");
    }
  };
  const handlePreviousUrlDownload = (documentId: PreviousImage) => {
    const prev = data?.response?.data;
    const filteredData = prev?.filter(
      (item: { previous_images: string | any[] }) =>
        item.previous_images && item.previous_images.length > 0
    );
    let matchingIndex = -1;
    filteredData.some((item: any, index: any) => {
      const exists = item.previous_images.some((image: any) => {
        return (
          image.path === documentId.path &&
          image.fullUrl === documentId.fullUrl &&
          image.expiry_date === documentId.expiry_date &&
          image.insurance_amount === documentId.insurance_amount
        );
      });
      if (exists) {
        matchingIndex = index;
        return true;
      }
      return false;
    });

    if (matchingIndex !== -1) {
      const encodedUrl = data?.response?.prevEncodedUrl[matchingIndex];
      window.open(encodedUrl, "_blank");
    } else {
      console.log("Document not found.");
    }
  };

  const handleShowMessage = () => {
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };
  const { data: machineDocTypeData } = useMachineDocumentTypeListQuery(orgId, {
    skip: !orgId,
  });
  const mappedData =
    data?.response?.data.map((item: any) => {
      const matchingDocument = machineDocTypeData?.response?.find(
        (doc: any) => doc._id === item.document_type);

      return {
        document_type: matchingDocument?.document_type || "Unknown Document Type",
        insurance_amount: item?.images[0]?.insurance_amount || "-",
        expiry_date: item?.images[0]?.expiry_date
          ? (() => {
            const date = new Date(item?.images[0].expiry_date * 1000);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
          })()
          : "-",
        createdAt: item.createdAt || "",
        _id: item._id || "",
        previous_images: item?.previous_images ?? [],
      };
    }) || [];

  const requiredDocumentTypes = [
    "Insurance",
    "Vehicle State Tax",
    "Vehicle Paper",
    "Vehicle India Permit",
    "Loan Paper",
    "Vehicle RC",
  ];

  const allDocumentsPresent = requiredDocumentTypes.every((type) =>
    mappedData.some(
      (doc: { document_type: string }) =>
        doc.document_type.trim() === type.trim()
    )
  );
  const deleteFn = (id: string) => {
    if (isButtonDisabled) {
      alert("Deletion of this document is disabled, as the corresponding site is deactivated.")
      return;
    }
    setIsClose(true);
    setId(id);
  };
  const editFn = (id: string) => {
    if (isButtonDisabled) {
      alert("Editing is disabled for this document, as the corresponding site is currently inactive.")
      return;
    }
    setMachineDocumentEditId(id);
    setShowModal(true);
  };
  return (
    <>
      <div>
        <Paper className="form-outers">
          <Toolbar>
            <Typography
              sx={{ flex: "1 1 40%" }}
              variant="h5"
              id="tableTitle"
              className="inner-headings"
              component="div"
              onClick={() => setOpen(true)}
            >
              Machine Documents
            </Typography>
            <div className="flex-boxinner">
              <Typography sx={{ flex: "1 1 auto" }}></Typography>
              {isButtonDisabled ? null : (
                <Tooltip
                  title={
                    allDocumentsPresent
                      ? "Documents already added"
                      : "Add Machine Document"
                  }
                  arrow
                  className="me-1 mt-1"
                >
                  <span>
                    <Button
                      className="secondary-btn"
                      onClick={() => {
                        setShowModal(true);
                        handleShowMessage();
                      }}
                      type="submit"

                      variant="contained"
                      color="primary"
                      disabled={allDocumentsPresent || isButtonDisabled}
                    >
                      Add Machine Document
                    </Button>
                  </span>
                </Tooltip>
              )}

              <Snackbar
                open={snackbarOpen && (!isSiteActive || (isSiteActive && !isActive))}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={
                  !isSiteActive
                    ? "Please activate site to add documents"
                    : "Please activate this machine to add documents"
                }
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ marginTop: "140px" }}
              />
            </div>
          </Toolbar>

          <TableContainer component={Paper} className="form-outers">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              className="table-outers"
            >
              <TableHead>
                <TableRow>
                  <TableHeadElement
                    setHead={tableColumns ?? []}
                    order={order}
                    orderBy={orderBy}
                    sortFn={(event, id: string) =>
                      createSortHandler(event, id as keyof SortingData)
                    }
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableBodyElement
                  selectedColumns={["document_type", "insurance_amount", "expiry_date", "createdAt"]}
                  setData={mappedData}
                  editFn={editFn}
                  deleteFn={deleteFn}
                  viewDownloadFn={viewDownloadFn}
                  viewDocumentFn={(id) => {
                    handleViewDocument(id);
                  }}
                  pageData={{
                    limit: Number(process.env.REACT_APP_PER_PAGE),
                    page: data?.response?.page as number ?? 0,
                  }}
                />
                <Dialog className="dialogBox-main" open={openDialog} onClose={handleClose}>
                  <DialogTitle>Previous Document Details</DialogTitle>
                  <DialogContent>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Expiry Date</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Download Invoice</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {previousImages?.map((image, index) => (
                          <TableRow key={image.path || `img-${index}`}>
                            <TableCell>
                              {new Date(
                                image.expiry_date * 1000
                              ).toLocaleDateString()}
                            </TableCell>
                            <TableCell>{image.insurance_amount}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handlePreviousUrlDownload(image)}
                              >
                                <DownloadIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}

                      </TableBody>
                    </Table>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
                </Dialog>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <ConfirmBox
          title="Machine Document Delete Confirmation"
          message="Are you sure, you want to delete this Machine Document?"
          open={isClose}
          onClose={confirmBoxClose}
        />
      </div>
      <Dialog className="dialogBox-main" onClose={handleModalClose} open={imageModalOpen}
        sx={{
          '& .MuiDialog-paper': {
            width: '20%',
            maxWidth: 'none',
            overflow: 'hidden',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Preview Document
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          <img src={imageUrl} alt="Fuel Receipt" />
        </DialogContent>
        <DialogActions>
          <Button
            className="close-btn"
            variant="outlined"
            color="error"
            onClick={handleModalClose}
          >
            Close
          </Button>
          <Button
            className="save-btn"
            variant="outlined"
            color="success"
            onClick={handleViewDownload}
            autoFocus
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog className="dialogBox-main" onClose={handlePreviousModalClose} open={previousImageModalOpen}
        sx={{
          '& .MuiDialog-paper': {
            width: '45%',
            maxWidth: 'none',
            overflow: 'hidden',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Preview Fuel Receipt
          <IconButton
            aria-label="close"
            onClick={handlePreviousModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          <img src={previousImageUrl} alt="Previous Document Image" />
        </DialogContent>
        <DialogActions>
          <Button
            className="close-btn"
            variant="outlined"
            color="error"
            onClick={handlePreviousModalClose}
          >``
            Close
          </Button>
          <Button
            className="save-btn"
            variant="outlined"
            color="success"
            onClick={handleViewDownload}
            autoFocus
          >
            Download
          </Button>
        </DialogActions>
      </Dialog> */}
      {showModal && isActive && isSiteActive && (
        <AddEditDocument
          id={machineDocumentEditId}
          showModal={showModal}
          setShowModal={setShowModal}
          setMachineEntryEditId={setMachineDocumentEditId}
          paramId={id}
        />
      )}
    </>
  );
};

export default memo(MachineDocument);
