import React, { useEffect, useMemo, useState } from 'react'
import {
    Button,
    Paper,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";
import { TableBodyElement } from '../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../components/TableBody/TableHeadElement';
import { ColumnsType, MachineType, Order, PageMeta, RouteInfo, SortingData } from '../../types/CommonTypes';
import { useDeleteUserMutation, useUpdateUserStatusMutation, useUserListQuery } from '../../redux-services/api/UserApi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox';
import routes from "../../AdminRouting";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import arrowImage from "../../assests/img/arrow.svg"
import { SearchElement } from '../../components/Search/Search';

const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "username",
        sort: true,
        label: "User Name",
    },
    {
        id: "email",
        sort: true,
        label: "Email",
    },
    {
        id: "phone",
        sort: false,
        label: "Contact No."
    },
    {
        id: "role_name",
        sort: false,
        label: "User Role"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const User = () => {

    const [routeInfo, setRouteInfo] = useState<RouteInfo>({
        path: "",
        exact: true,
        name: "",
        element: null,
    });
    const [routeData, setRouteData] = useState<RouteInfo[]>([]);
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };

    let navigate = useNavigate();
    let query = useLocation();
    const userDetail = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
        let routesData: RouteInfo[] = [];
        if (userDetail !== null) {
            if (userDetail?.organization_id === '') {
                if (userDetail?.role_type === 0) {
                    routesData = routes?.SuperAdminRoute ?? [];
                } else {
                    routesData = routes?.SubSuperAdminRoute ?? [];
                }
            } else {
                routesData = routes?.OrgAdminRoute ?? [];
            }
        }
        setRouteData(routesData);
        let routeInfo: RouteInfo | any = routesData.find((item: RouteInfo) => query?.pathname === (item?.path))
        setRouteInfo(routeInfo);
    }, [routes, query, userDetail]);


    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
    const [activeMachineId, setActiveMachineId] = useState<string>("");
    const [machineStatus, setMachineStatus] = useState<boolean>(false);
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [userList, setUserList] = useState<PageMeta>(initialState);
    const [isClose, setIsClose] = useState<boolean>(false);
    const [Id, setId] = useState<string>("");
    const [userListData, setUserListData] = useState<any>({
        data: [],
        last_page: 1,
        page: 0,
        total: 0,
    });


    console.log('User Component called')
    const UserListApiCall = useUserListQuery({
        per_page: userList.per_page ?? rowsPerPage,
        page: query.state ?? userList.page,
        order: userList.order ?? order,
        order_by: userList.order_by ?? orderBy,
        searchText: userList.searchText ?? search,
        user_id: userDetail?._id ?? '',
        site_id: "",
        organization_id: userDetail?.organization_id
    }, { skip: !userDetail?._id || routeInfo?.path === '' });

    useEffect(() => {
        if (UserListApiCall?.data !== undefined) {
            setUserListData(UserListApiCall?.data?.response)
        }
    }, [UserListApiCall]);

    const [deleteUser] = useDeleteUserMutation();
    const [updateActiveStatus] = useUpdateUserStatusMutation();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setUserList({
            ...userList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setUserList({ ...userList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setUserList({
            ...userList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setUserList({
                page: 1,
                per_page: userList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteUser(Id);
        } else {
            setIsClose(res);
        }
    };
    const handleToggleActive = (id: string) => {
        const machine = UserListApiCall?.data?.response.data.find((machine: MachineType) => machine._id === id);
        if (machine) {
            setActiveMachineId(id);
            setIsConfirmationOpen(true);
            setMachineStatus(machine.is_active);
        }
    };
    const handleConfirmationResponse = (confirmed: boolean) => {
        if (confirmed) {
            const newStatus = !machineStatus;
            updateActiveStatus({ id: activeMachineId, status: newStatus });
        }
        setActiveMachineId("");
        setIsConfirmationOpen(false);
    };
    const breadcrumbs = [
        { name: `${routeInfo?.name}-List`, to: '/admin/user' },

    ];

    return (
        <div className='table'>
            <Toolbar>
                <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                    {breadcrumbs.map((crumb, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                            {index === breadcrumbs.length - 1 ? (
                                <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                            ) : (
                                <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Toolbar>
            <Paper className="table-outers">
                <Toolbar>
                    <Typography
                        sx={{ flex: '1 1 40%' }}
                        variant="h5"
                        id="tableTitle"
                        component="div"
                        className="inner-headings"
                    >
                        {routeInfo?.name} List
                    </Typography>

                    <div className="flex-boxinner">
                        <Typography
                            sx={{ flex: '1 1 auto' }}
                        >
                            <SearchElement
                                searchFn={searchHandler}
                                searchTag={`Search ${routeInfo?.name}...`}
                            />

                        </Typography>
                        <Tooltip title={`Add ${routeInfo?.name}`} arrow className="me-1 mt-1">
                            <Button className="secondary-btn" onClick={() => navigate(routeInfo?.path + '/add')} type="submit" variant="contained" color="primary">Add {routeInfo?.name}</Button>
                        </Tooltip>
                    </div>
                </Toolbar>
                <StyledEngineProvider>
                    <TableContainer component={Paper} className="table-outers">
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small" aria-label="a dense table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableHeadElement
                                        setHead={tableColumns ? tableColumns : []}
                                        order={order}
                                        orderBy={orderBy}
                                        sortFn={(event, id: string) =>
                                            createSortHandler(event, id as keyof SortingData)
                                        }
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableBodyElement
                                    selectedColumns={[
                                        "username",
                                        "email",
                                        "phone",
                                        "role_name"
                                    ]}
                                    setData={userListData.data ? userListData.data : []}
                                    viewProfileFn={(id) =>
                                        navigate(`/admin/profile/${id}`, {
                                            state: page,
                                        })
                                    }
                                    editFn={(id) =>
                                        navigate(`${routeInfo?.path}/edit/${id}`, {
                                            state: page,
                                        })
                                    }
                                    deleteFn={(id) => {
                                        setIsClose(true);
                                        setId(id);
                                    }}
                                    navigationFn={(id)=>{
                                        navigate(`/admin/profile/${id}`)
                                }}
                                    isActivefn={(id) => handleToggleActive(id)}
                                    pageData={{
                                        limit:
                                            rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                        page: userListData.page as number,
                                    }}
                                />
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={userListData.total ?? 0}
                            page={userListData.page ?? 0}
                            rowsPerPageOptions={[20, 50, 100]}
                            onPageChange={handleChangePage}
                            rowsPerPage={
                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                            }
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </StyledEngineProvider>
            </Paper>
            <ConfirmBox
                title={`User ${machineStatus ? 'Deactivate' : 'Activate'} Confirmation`}
                message={activeMachineId ? `Are you sure you want to ${machineStatus ? 'deactivate' : 'activate'} this User?` : ''}
                open={isConfirmationOpen}
                onClose={handleConfirmationResponse}
            />
            <ConfirmBox
                title="User Delete Confirmation"
                message="Are you sure, you want to delete this User?"
                open={isClose}
                onClose={confirmBoxClose}
            />
        </div>
    )
}

export default User