export interface FuelOptions {
  name: string;
  value: number;
}
export const FuelTypeList: FuelOptions[] = [
  { name: "Diesel", value: 1 },
  { name: "Urea", value: 2 },
  { name: "Petrol", value: 3 },
];

export const FuelUnit: { [key: number]: string } = { 1: "Ltr.", 2: "Kg." };

export const FuelType: { [key: number]: string } = {
  1: "Diesel",
  2: "Urea",
  3: "Petrol",
};

export const FuelTypes = [
  { id: 1, title: "Diesel" },
  { id: 2, title: "Urea" },
  { id: 3, title: "Petrol" },
];

export const FuelUnitTypes = [
  { id: 1, title: "Ltr." },
  { id: 2, title: "Kg." },
];
export interface VehicleTypeOptions {
  name: string;
  value: number;
}
export const VehicleTypeList: VehicleTypeOptions[] = [
  { name: "Tata Edge", value: 1 },
  { name: "Mahindra Edge", value: 2 },
];

export const VehicleType: { [key: number]: string } = {
  1: "Tata Edge",
  2: "Mahindra Edge",
};

export const DistanceTravel = {
  Hours: 1,
  Km: 2,
};

export const MachineType = [
  { id: 1, title: "Tipper" },
  { id: 2, title: "Pickup" },
  { id: 3, title: "Roller" },
  { id: 4, title: "Excavator" },
  { id: 5, title: "Camper" },
];

export const WorkItems = [
  { id: 1, title: "Soil Dumping" },
  { id: 2, title: "Rolling" },
  { id: 3, title: "Grading" },
  { id: 4, title: "Dozing" },
];

export const MachineMeasurement = [
  { id: 1, title: "trip" },
  { id: 2, title: "hours" },
  { id: 3, title: "km" },
];

export const MapConfig = {
  params: {
    center: [26.9124, 75.7873],
    zoom: 13,
    minZoom: 1,
    maxZoom: 20,
  },
  tileLayer: {
    uri: "https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
    uri2: "https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
    params: {
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
      maxZoom: 20,
      attribution: "&copy; Google",
    },
  },
};

export const MapIcon = {
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
};

export const ServiceType = [
  { id: 1, title: "Regular Service" },
  { id: 2, title: "Tyre Repair" },
  { id: 3, title: "Tyre Change" },
  { id: 4, title: "Puncture" },
  { id: 5, title: "Accessories" },
  { id: 6, title: "Breakdown" },
  { id: 7, title: "Toll Expense" },
];

export const VALIDATION_MESSAGES = {
  password:
    "Password must contain at least one lowercase alphabet, one uppercase alphabet, one special symbol, one number, and be at least 8 characters long!",
  emptyPasswordValidation: "Password is required!",
};

export interface ShiftNameOptions {
  name: string;
}
export const ShiftNameList: ShiftNameOptions[] = [
  { name: "Morning" },
  { name: "Evening" },
];
