import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const MachineServiceApi = createApi({
  reducerPath: "machineServiceApi",

  baseQuery: axiosBaseQuery(),

  tagTypes: ["machineServiceTag"],

  endpoints: (builder) => ({
    machineServiceList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/machine_service/getlist`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
          id: pageData.id ?? undefined,
          site_id: pageData.site_id ?? undefined,
        },
      }),
      providesTags: ["machineServiceTag"],
    }),

    createMachineService: builder.mutation<Response<any>, FormData>({
      query: (data: FormData) => ({
        url: `/machine_service/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["machineServiceTag"],
    }),

    getMachineServiceById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/machine_service/${id}`,
        method: "GET",
      }),
      providesTags: ["machineServiceTag"],
    }),
    getMachineServiceBySiteId: builder.query<Response<any>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/machine_service/get/servicebysite`,
        method: "GET",
        params: {
          site_id: pageData?.site_id ?? "",
          work_item_id: pageData?.work_item_id ?? "",
        }
      }),
      providesTags: ["machineServiceTag"],
    }),  
    editMachineService: builder.mutation<Response<any>, FormData>({
      query: (data: FormData) => ({
        url: `/machine_service/edit/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["machineServiceTag"],
    }),
    deleteMachineService: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/machine_service/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["machineServiceTag"],
    }),
  }),
});

export const {
  useMachineServiceListQuery,
  useCreateMachineServiceMutation,
  useGetMachineServiceByIdQuery,
  useGetMachineServiceBySiteIdQuery,
  useEditMachineServiceMutation,
  useDeleteMachineServiceMutation
} = MachineServiceApi;
