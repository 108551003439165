import { FC } from 'react'
import { Dialog, DialogActions, DialogTitle,DialogContent,Button } from "@mui/material";

const ImagePreviewDialog:FC<{open:boolean,onClose:any,image:any}>=({
    open,onClose,image
}) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth className="dialogBox-main" >
          <DialogTitle>Preview</DialogTitle>
          <DialogContent>
            <img
              src={image}
              alt="Preview"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                maxHeight: "80vh",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              className="close-btn"
              variant="outlined"
              color="error"
              onClick={onClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      );
}

export default ImagePreviewDialog