import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Paper,
  Snackbar,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  debounce,
} from "@mui/material";
import { TableBodyElement } from "../../../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../../../types/CommonTypes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmBox from "../../../../components/ConfirmBox/ConfirmBox";
import { SearchElement } from "../../../../components/Search/Search";
import {
  useDeleteBoreAreaMutation,
  useBoreAreaListQuery,
  useGetSitesByIdQuery,
} from "../../../../redux-services";
import AddEditBoreArea from "./AddEditBoreArea";

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "name",
    sort: true,
    label: "Name",
  },
  {
    id: "soil_assigned",
    sort: true,
    label: "Soil Assigned",
  },
  {
    id: "distance",
    sort: true,
    label: "Lead Distance",
  },
  {
    id: "compression_factor",
    sort: true,
    label: "Compression Factor",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "18%" },
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const BoreArea: FC<{ siteDetail: { id: string; name: string } }> = ({
  siteDetail,
}) => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  let navigate = useNavigate();
  let query = useLocation();
  let { id: siteId } = useParams();
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [isClickable, setIsClickable] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [boreAreaList, setBoreAreaList] = useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { data } = useBoreAreaListQuery({
    per_page: boreAreaList.per_page ?? rowsPerPage,
    page: query.state ?? boreAreaList.page,
    order: boreAreaList.order ?? order,
    order_by: boreAreaList.order_by ?? orderBy,
    searchText: boreAreaList.searchText ?? search,
    id: siteId,
  });
  const { data: getData } = useGetSitesByIdQuery(siteId);
  useEffect(() => {
    if (getData?.status === 200) {
      let data = getData?.response;
      setIsButtonDisabled(data.is_deactivate);
      setIsActive(data.is_active);
    }
  }, [getData]);

  const siteCoordinates: any = getData?.response?.location?.coordinates;
  const [deleteBoreArea] = useDeleteBoreAreaMutation();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setBoreAreaList({
      ...boreAreaList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setBoreAreaList({ ...boreAreaList, page: newPage + 1 });

    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setBoreAreaList({
      ...boreAreaList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setBoreAreaList({
        page: 1,
        per_page: boreAreaList.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, []);

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteBoreArea(Id);
    } else {
      setIsClose(res);
    }
  };
  const handleModel = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);
  const handleShowMessage = () => {
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };
  const deleteFn = (id: string) => {
    if (isButtonDisabled) {
      alert("Completed site cannot be deleted");
      return;
    }
    setIsClose(true);
    setId(id);
  };
  const editFn = (id: string) => {
    if (isButtonDisabled) {
      alert("Site cannot be edited as its deactivated");
      return;
    }
    setOpen(true);
    setEditId(id);
  };
  return (
    <div>
      <Paper className="table-outers mb-2">
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 40%" }}
            variant="h5"
            id="tableTitle"
            component="div"
            className="inner-headings">
            Bore Area List
          </Typography>
          <div className="flex-boxinner">
            <Typography sx={{ flex: "1 1 auto" }}>
              <SearchElement
                searchFn={searchHandler}
                searchTag={"Search Bore Area..."}
              />
            </Typography>
            {isButtonDisabled ? null : (
              <Tooltip title="Add Bore Area" arrow className="me-1 mt-1">
                <Button
                  className="secondary-btn"
                  onClick={() => {
                    setOpen(true);
                    setEditId("");
                    handleShowMessage();
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isButtonDisabled}>
                  Add Bore Area
                </Button>
              </Tooltip>
            )}
            <Snackbar
              open={snackbarOpen && !isActive}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
              message="Please activate this site to add Bore Area logs."
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              style={{ marginTop: "140px" }}
            />
          </div>
        </Toolbar>
        <StyledEngineProvider>
          <TableContainer component={Paper} className="table-outers">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              className="table-outers">
              <TableHead>
                <TableRow>
                  <TableHeadElement
                    setHead={tableColumns ? tableColumns : []}
                    order={order}
                    orderBy={orderBy}
                    sortFn={(event: any, id: string) =>
                      createSortHandler(event, id as keyof SortingData)
                    }
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableBodyElement
                  selectedColumns={[
                    "name",
                    "soil_assigned",
                    "distance",
                    "compression_factor",
                  ]}
                  setData={data?.response.data ? data?.response.data : []}
                  deleteFn={deleteFn}
                  editFn={editFn}
                  pageData={{
                    limit:
                      rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: data?.response.page as number,
                  }}
                />
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={data?.response.total ?? 0}
              page={data?.response.page ?? 0}
              rowsPerPageOptions={[20, 50, 100]}
              onPageChange={handleChangePage}
              rowsPerPage={
                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </StyledEngineProvider>
      </Paper>
      <ConfirmBox
        title="Bore Area Delete Confirmation"
        message="Are you sure, you want to delete this Bore Area?"
        open={isClose}
        onClose={confirmBoxClose}
      />
      {open && isActive && (
        <AddEditBoreArea
          siteCoordinate={siteCoordinates}
          open={open}
          handleModel={handleModel}
          siteInfo={siteDetail}
          id={editId}
        />
      )}
    </div>
  );
};

export default memo(BoreArea);
