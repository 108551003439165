import React, { useCallback, useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import LeafMap from "leaflet";
import {
  useGetSitesByIdQuery,
  useGetVehicleDetailsQuery,
  useGetVehicleStatusQuery,
  useMachineInfoListQuery,
  useVehicleInfoListWoIdQuery,
} from "../../../redux-services";
import { IconOptions } from "leaflet";
import SiteAddEditModal from "../../../components/Models/SiteAddEditModal";
import { Order, PageMeta, SortingData } from "../../../types/CommonTypes";
import { useLocation, useParams } from "react-router-dom";
import useLeafletMap from "../../../components/LeafLetMap/useLeafletMap";
import { MapConfig, MapIcon } from "../../../ constants";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import L from "leaflet";
import "leaflet.markercluster";
import RateChartCard from "../RateChartCard/RateChartCard";
import { useGetChartQuery } from "../../../redux-services/api/RateChartApi";
interface SiteState {
  id: string;
  name: string;
  location: {
    coordinates: any[];
  };
  address: string;
  city: string;
  contact_no: string;
  state: string;
  country: string;
  postal_code: string;
  encodedUrl: string[];
}

interface RateState {
  road_side_fixed_rate: string;
  road_side_additional_rate: string;
  bore_area_fixed_rate: string;
  bore_area_additional_rate: string;
  distance_limit: string;
}

const initialRateState = {
  road_side_fixed_rate: "",
  road_side_additional_rate: "",
  bore_area_fixed_rate: "",
  bore_area_additional_rate: "",
  distance_limit: "",
};

let initialOrder: string = process.env.REACT_APP_ORDER as string;
const SiteDetails: React.FC<{
  id: string;
  setSiteDetail: any;
  org_id: any;
}> = ({ id, setSiteDetail, org_id }) => {
  const [open, setOpen] = useState<boolean>(false);
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  // const [openVehicleInfoModal, setOpenVehicleInfoModal] =
  //   useState<boolean>(false);
  const organization_id = localStorage.getItem("orgId");
  const [siteData, setSiteData] = useState<SiteState | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [vehicleOdoDetails, setVehicleOdoDetails] = useState<any[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isEditButtonDisabled, setEditIsButtonDisabled] = useState(false);
  const { data: getData } = useGetSitesByIdQuery(id);
  const [machineInfoList, setMachineInfoList] =
    useState<PageMeta>(initialState);
  let orgId = organization_id || org_id;
  const [openRateCardModal, setOpenRateCardModal] = useState<boolean>(false);
  const [RateData, setRateData] = useState<RateState | null>(initialRateState);

  let gpsData: any = [];
  const [initialZoomDone, setInitialZoomDone] = useState(false);
  let mapVehicle: any;
  const mapRefVehicle = useRef<HTMLDivElement>(null);
  mapVehicle = useLeafletMap(mapRefVehicle, MapConfig.tileLayer.uri);
  const { data: getVehicleStatusData } = useGetVehicleStatusQuery(orgId, {
    skip: !orgId,
  });

  let { id: siteId } = useParams<string>();
  const {
    data: chartData,
    isLoading: isChartLoading,
    error: chartError,
  } = useGetChartQuery(siteId as string, {
    skip: !siteId,
  });
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const { data } = useVehicleInfoListWoIdQuery({});
  const vehiclesData = data?.response;
  let query = useLocation();

  const vehicles_result = getVehicleStatusData?.response?.result;
  const vehicle_data = vehicles_result?.vehicle_data;

  const { data: machineryData } = useMachineInfoListQuery({
    order: machineInfoList.order ?? order,
    order_by: machineInfoList.order_by ?? orderBy,
    id: siteId,
  });
  const getFilteredObjectsWithMatchingRegNumbers = (
    array1: any,
    array2: any
  ) => {
    const array2RegNumbers = array2?.response?.data?.map(
      (item: any) => item.number_plate
    );

    const filteredArray = array1
      ?.map((item: any) => {
        const matchingVehicleInfo = item?.vehicle_info?.filter((vehicle: any) =>
          array2RegNumbers?.includes(vehicle.vehicle_reg_number)
        );
        if (matchingVehicleInfo?.length === 0) return null;
        return { ...item, vehicle_info: matchingVehicleInfo };
      })
      .filter((item: any) => item !== null);
    return filteredArray;
  };

  const newFilteredResults = getFilteredObjectsWithMatchingRegNumbers(
    vehiclesData,
    machineryData
  );

  let regNosArray: any;
  if (newFilteredResults) {
    regNosArray = newFilteredResults[0]?.vehicle_info?.map(
      (vehicle: any) => vehicle.vehicle_reg_number
    );
  }

  useEffect(() => {
    if (!getData) return;
    const { status, response } = getData;
    if (status === 200 && response) {
      const { _id, name, is_deactivate, is_active, ...siteDetails } = response;
      let data = getData?.response;
      console.log(response, "Response")
      setSiteData({ id: _id, name, ...siteDetails });
      setSiteDetail({ id: _id, name });
      setIsButtonDisabled(is_deactivate || !is_active);
      setEditIsButtonDisabled(data.is_deactivate)
      console.log(isEditButtonDisabled, "disabled")
      setLoading(false);
    }
  }, [getData, setSiteDetail]);

  if (vehicle_data) {
    const result = Object.values(vehicle_data)?.map((item: any) => ({
      vins: item.vin as string,
      speed: item.speed as number,
      odometer: item.odometer as number,
      gpsLatitude: item.gpsLatitude as number,
      gpsLongitude: item.gpsLongitude as number,
      status: item.status,
    }));
    gpsData = result;
  } else {
    gpsData = [];
  }

  const vinsToRegNumbers = data?.response?.flatMap((item: any) =>
    item.vehicle_info.map((vehicle: any) => ({
      [vehicle.vins_id]: vehicle.vehicle_reg_number,
    }))
  );
  const vinsToRegNumberss = vinsToRegNumbers?.reduce((acc: any, curr: any) => {
    const [vins, reg] = Object.entries(curr)[0];
    acc[vins] = reg;
    return acc;
  }, {});

  useEffect(() => {
    if (mapRefVehicle && gpsData && mapVehicle && vehicle_data) {
      const markersClusters = L.markerClusterGroup();
      markersClusters.clearLayers();
      const markersLayer = LeafMap.featureGroup();
      let validMarkerAdded = false;

      gpsData?.forEach((data: any, i: number) => {
        if (
          regNosArray &&
          data
          //regNosArray?.indexOf(vinsToRegNumberss[`${data.vins}`]) !== -1
        ) {
          const marker = LeafMap.marker([
            data?.gpsLatitude,
            data?.gpsLongitude,
          ]);
          marker.setIcon(LeafMap.icon(MapIcon as IconOptions));
          mapVehicle?.flyTo(
            { lat: data?.gpsLatitude, lng: data?.gpsLongitude },
            mapVehicle?.getZoom()
          );

          const popup = LeafMap.popup({
            closeButton: false,
            className: "leak-marker",
            offset: [2, 1],
          }).setContent(
            `<div class="c-tooltip">
                          <table width="100%" class="vaolum-data-table">
                              <thead><tr><th colspan='2'>Vehicle Info</th></tr></thead>
                              <tbody>
                                  <tr>
                                      <td>Reg No</td>
                                      <td>${vinsToRegNumberss[
            `${gpsData[i]?.vins}`
            ] || "Not found"
            }</td>
                                  </tr>
                                  <tr>
                                      <td>Latitude</td>
                                      <td>${gpsData[i]?.gpsLatitude}</td>
                                  </tr>
                                  <tr>
                                      <td>Longitude</td>
                                      <td>${gpsData[i]?.gpsLongitude}</td>
                                  </tr>
                                  <tr>
                                      <td>Odometer</td>
                                      <td>${gpsData[i]?.odometer}</td>
                                  </tr>
                                  <tr>
                                      <td>Speed</td>
                                      <td>${gpsData[i]?.speed}</td>
                                  </tr>
                                  <tr>
      <td>Status</td>
      <td id="${gpsData[i]?.status}">${gpsData[i]?.status}</td>
  </tr>
  
                              </tbody>
                          </table>
                      </div>`
          );

          marker.bindPopup(popup);
          markersLayer.addLayer(marker);
          validMarkerAdded = true;

          marker.on("mouseover", (e: any) => {
            e.target.openPopup();
          });
          marker.on("mouseout", (e: any) => {
            e.target.closePopup();
          });
          markersClusters.addLayer(marker);
        }
      });

      if (validMarkerAdded) {
        mapVehicle?.addLayer(markersLayer);
        mapVehicle?.fitBounds(markersLayer.getBounds(), { maxZoom: 15 });
        mapVehicle?.addLayer(markersClusters);
      }

      if (
        !initialZoomDone &&
        gpsData[0]?.gpsLatitude &&
        gpsData[0]?.gpsLongitude
      ) {
        mapVehicle?.flyTo(
          [gpsData[0].gpsLatitude, gpsData[0].gpsLongitude],
          15
        );
        setInitialZoomDone(true);
      }

      mapVehicle?.invalidateSize();
    }
  }, [mapVehicle, gpsData, vehicle_data]);

  const { data: getVehicleDetailsData } = useGetVehicleDetailsQuery(
    { id: orgId, siteId: id },
    {
      skip: !orgId,
    }
  );
  useEffect(() => {
    if (getVehicleDetailsData?.response) {
      setVehicleOdoDetails(getVehicleDetailsData?.response);
    }
  }, [getVehicleDetailsData?.response, vehicleOdoDetails]);

  useEffect(() => {
    if (chartData?.response) {
      const {
        road_side_fixed_rate,
        road_side_additional_rate,
        bore_area_fixed_rate,
        bore_area_additional_rate,
        distance_limit,
      } = chartData.response;
      setRateData({
        road_side_fixed_rate: road_side_fixed_rate || "0",
        road_side_additional_rate: road_side_additional_rate || "0",
        bore_area_fixed_rate: bore_area_fixed_rate || "0",
        bore_area_additional_rate: bore_area_additional_rate || "0",
        distance_limit: distance_limit || "0",
      });
    }
  }, [chartData]);

  const handleModel = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);

  const handleRateCardModal = useCallback((isOpen: boolean) => {
    setOpenRateCardModal(isOpen);
  }, []);

  const handleRateDataUpdate = (newRateData: any) => {
    setRateData(newRateData);
  };

  return (
    <>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Box>
          <Box className="p-0">
            <Grid container spacing={2}>
              <Grid item md={12} xl={12}>
                <Box className="cardMain">
                  <Box className="cardMainInner">
                    <Card className="form-outers addressForm p-0">
                      <CardHeader
                        className="inner-headings"
                        title={"Address"}
                      />
                      <Tooltip
                        title={
                          isButtonDisabled
                            ? "Action not allowed as site is deactivated/completed"
                            : "Edit"
                        }
                        arrow className="me-1 mt-1"
                      >
                        {isEditButtonDisabled ? (
                          <></>
                        ) : (
                          <Button
                            onClick={() => {
                              if (!isButtonDisabled) {
                                handleModel(true);
                              }
                            }}
                            className="editicon"
                          >
                            <EditIcon />
                          </Button>
                        )}

                      </Tooltip>
                      <CardContent>
                        <Typography>{siteData?.address}</Typography>
                        <Typography>
                          {siteData?.city}, {siteData?.state}
                        </Typography>
                        <Typography>
                          {siteData?.city.toUpperCase()},{" "}
                          {siteData?.state.toUpperCase()},{" "}
                          {siteData?.postal_code}
                        </Typography>
                        <Typography>{siteData?.country}</Typography>
                        <Typography>Phone number: {siteData?.contact_no}</Typography>
                      </CardContent>
                    </Card>
                  </Box>
                  <Box className="cardMainInner">
                    <Card className="form-outers addressForm p-0">
                      <CardHeader
                        className="inner-headings"
                        title={"Rate Card"}
                      />
                      <CardContent className="p-0">
                        <Tooltip
                          title={
                            isButtonDisabled
                              ? "Action not allowed as site is deactivated/completed"
                              : "Edit"
                          }
                          arrow
                          className="me-1 mt-1"
                        >
                          {isButtonDisabled ? (
                            <></>
                          ) : (
                            <Button
                              onClick={() => {
                                if (!isButtonDisabled) {
                                  handleRateCardModal(true);
                                }
                              }}
                              className="editicon"
                            >
                              <EditIcon />
                            </Button>
                          )}
                        </Tooltip>

                        {isChartLoading ? (
                          <Typography>Loading Rate Card...</Typography>
                        ) : chartError ? (
                          <Typography>
                            Error fetching Rate Card details
                          </Typography>
                        ) : (
                          <List className="ratecard-list">
                            <ListItem>
                              Distance Limit in km{" "}
                              <span>{RateData?.distance_limit ?? "0"}</span>
                            </ListItem>
                            <ListItem>
                              Road Side Fixed Rate in ₹
                              <span>
                                {RateData?.road_side_fixed_rate ?? "0"}
                              </span>
                            </ListItem>
                            <ListItem>
                              Road Side Additional Rate After{" "}
                              {RateData?.distance_limit ?? "0"} (per/km) in ₹
                              <span>
                                {RateData?.road_side_additional_rate ?? "0"}
                              </span>
                            </ListItem>
                            <ListItem>
                              Bore Area Fixed Rate in ₹
                              <span>
                                {RateData?.bore_area_fixed_rate ?? "0"}
                              </span>
                            </ListItem>
                            <ListItem>
                              Bore Area Additional Rate After{" "}
                              {RateData?.distance_limit ?? "0"} (per/km) in ₹
                              <span>
                                {RateData?.bore_area_additional_rate ?? "0"}
                              </span>
                            </ListItem>
                          </List>
                        )}
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {open && (
              <SiteAddEditModal
                open={open}
                sitesData={siteData as any}
                handleModel={handleModel}
                isEdit={true}
              />
            )}

            {openRateCardModal && siteData && (
              <RateChartCard
                open={openRateCardModal}
                RateData={RateData as any}
                handleRateCardModal={handleRateCardModal}
                isEdit={true}
                siteDetail={siteData}
                onRateDataUpdate={handleRateDataUpdate}
              />
            )}
          </Box>
        </Box >
      )}
      <Box
        className="map-container"
        style={{
          height: "900px",
          display:
            mapVehicle && gpsData && regNosArray && vinsToRegNumberss
              ? "block"
              : "none",
        }}>
        <Box
          style={{
            width: vehicle_data ? "100%" : "0%",
            height: "100%",
            display: vehicle_data ? "block" : "none",
          }}>
          <h4 style={{ color: "white", margin: "40px 40%" }}>
            Vehicle Location
          </h4>
          <div
            ref={mapRefVehicle}
            id="mapVehicle"
            style={{ width: "100%", height: "80%" }}></div>
        </Box>
      </Box>
    </>
  );
};

export default SiteDetails;
