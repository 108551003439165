import { ChangeEvent, FC, SyntheticEvent, useEffect, useState, memo } from "react";
import { Box, Button, Container, Divider, Fade, Grid, Modal, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Backdrop from '@mui/material/Backdrop';
import Error from "../../../../components/ErrorBox/Error";
import { useEditFuelTransactionMutation, useGetFuelTransactionByIdQuery, useGetSitesByIdQuery } from "../../../../redux-services";
import dayjs from "dayjs";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DescriptionIcon from "@mui/icons-material/Description";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImagePreviewDialog from "../../../../components/Preview/PreviewDialog";
const style = {
    position: 'absolute' as 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
};

export interface FormFields {
    refill_quantity: string;
    price_per_unit: string;
    total_fuel_price: string;
    date_time: string | Record<string, any>;
    fuelReceipt_img: any;
    fuelReceipt_img_url: any;
    fuelReceipt_img_type: any;
    organization_name: any;
}

const EditFuelTransaction: FC<{ open: boolean, handleModel: any, transactionId?: string, handleViewModal: any }> = ({ open, handleModel, transactionId, handleViewModal }) => {
    console.log(transactionId, "das")
    const initialState: FormFields = {
        refill_quantity: '',
        price_per_unit: '',
        total_fuel_price: '',
        date_time: '',
        fuelReceipt_img: null,
        fuelReceipt_img_url: "",
        organization_name: "",
        fuelReceipt_img_type: ""
    };

    const { data: transactionData } = useGetFuelTransactionByIdQuery(transactionId, {
        skip: !transactionId,
    });

    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState<FormFields>(initialState);
    const [openDialog, setOpenDialog] = useState(false);

    const site_id = transactionData?.response.site_id;
    const { data: SitesData } = useGetSitesByIdQuery(site_id, {
        skip: site_id ? false : true,
    });
    const organizationName = SitesData?.response.organization_id?.[0]?.organization_name || '';
    const [editFuelTransaction, editResult] = useEditFuelTransactionMutation();
    const [selectedImage, setSelectedImage] = useState("");

    const handleImageClick = (image: any) => {
        setSelectedImage(image);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedImage("");
    };
    useEffect(() => {
        if (transactionData?.status === 200) {
            console.log(transactionData?.response?.fuelReceipt_img_url, "Dsa")
            const unixTimestamp = transactionData?.response.date_time;
            const dateObject = dayjs(new Date(unixTimestamp));
            setFormData({
                refill_quantity: transactionData?.response.refill_quantity,
                price_per_unit: transactionData?.response.price_per_unit,
                total_fuel_price: transactionData?.response.total_fuel_price,
                date_time: dateObject,
                fuelReceipt_img: transactionData?.response.fuelReceipt_img?.path,
                fuelReceipt_img_url: transactionData?.response.fuelReceipt_img_url,
                organization_name: organizationName,
                fuelReceipt_img_type: "image"

            });
        }
    }, [transactionData, SitesData]);

    useEffect(() => {
        if (editResult.isSuccess && editResult.data.status === 200) {
            handleViewModal(false);
        }
    }, [editResult, handleModel]);



    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        const { name, value } = e.target;
        let newFormData = { ...formData, [name]: value };

        if (name === 'refill_quantity' || name === 'price_per_unit') {
            const refill_quantity = name === 'refill_quantity' ? parseFloat(value) : parseFloat(newFormData.refill_quantity);
            const price_per_unit = name === 'price_per_unit' ? parseFloat(value) : parseFloat(newFormData.price_per_unit);
            newFormData.total_fuel_price = (refill_quantity * price_per_unit).toString();
        }

        setFormData(newFormData);
    };

    const handleChangeImage = (event: any) => {
        let file = event.target.files[0];
        let setFileUrl = (URL.createObjectURL(file));
        setFormData({
            ...formData,
            fuelReceipt_img: file,
            fuelReceipt_img_url: setFileUrl,
        });
        const thumbnailElement = document.getElementById('images') as HTMLInputElement | null;
        if (thumbnailElement) {
            thumbnailElement.value = '';
        }
    };

    const handleDateTimeChange = (fieldName: string, value: any) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldName]: new Date(value).valueOf()
        }));
    };

    const handleDocumentChange = (event: any, id: string) => {

        let file = event.target.files[0];
        const fileTypes = [
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/bmp",
            "image/webp",
            "image/tiff",
            "image/svg+xml",
            "image/heic",
            "image/heif",

            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

            "application/pdf",
        ];

        const wordFileTypes = [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        const pdfFileTypes = ["application/pdf"];
        let fileType = "image";

        if (pdfFileTypes.includes(file.type)) {
            fileType = "pdf";
        }
        if (wordFileTypes.includes(file.type)) {
            fileType = "word";
        }

        if (fileTypes.includes(file.type)) {
            let nameUrl = event.target.name + "_url";
            let file_type = event.target.name + "_type";
            const url = URL.createObjectURL(file);
            setFormData({
                ...formData,
                [event.target.name]: file,
                [nameUrl]: url,
                [file_type]: fileType,
            });
        } else {
            if (document.getElementById(id)) {
                let field: any = document.getElementById(id);
                field.value = "";
            }
            alert("This file type is not allowed.");
            setFormData({
                ...formData,
                [event.target.name]: "",
            });
        }
    };

    const handleSubmit = (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        e.preventDefault();
        setSubmitted(true);
        let datee_time: any;
        if (typeof formData.date_time === 'object') {
            datee_time = formData.date_time.valueOf();
        } else {
            datee_time = formData.date_time;
        }
        let data = new FormData();
        data.append("fuelReceipt_img", formData.fuelReceipt_img as any);
        data.append("refill_quantity", formData.refill_quantity);
        data.append("price_per_unit", formData.price_per_unit);
        data.append("total_fuel_price", formData.total_fuel_price);
        data.append("date_time", datee_time.toString());
        data.append("organization_name", formData.organization_name);
        transactionId && data.append("transactionId", transactionId);
        editFuelTransaction(data as any);
    };

    if (editResult.isSuccess && editResult.data.status === 200) {
        handleModel(false);
    }

    return (
        <>
            <Container maxWidth={false} className="p-0">
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style} className='form-outers karuModal'>
                            <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                                Edit Fuel Transaction
                            </Typography>
                            <HighlightOffIcon className="closeicon" onClick={() => handleModel(false)} />
                            <Box className="modalBody">
                                <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                                <form onSubmit={handleSubmit} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="refill_quantity"
                                                label="Refill Quantity"
                                                name="refill_quantity"
                                                type="number"
                                                size='small'
                                                value={formData.refill_quantity}
                                                onChange={handleChange}
                                            />
                                            <Error current_key="refill_quantity" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="price_per_unit"
                                                label="Price Per Unit"
                                                name="price_per_unit"
                                                type="number"
                                                size='small'
                                                value={formData.price_per_unit}
                                                onChange={handleChange}
                                            />
                                            <Error current_key="price_per_unit" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="total_fuel_price"
                                                label="Total Amount"
                                                name="total_fuel_price"
                                                type="number"
                                                size='small'
                                                value={formData.total_fuel_price}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                            <Error current_key="total_fuel_price" />
                                        </Grid>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Grid item xs={6} className='custom-datepicker'>
                                                <DateTimePicker
                                                    label="Time"
                                                    name="date_time"
                                                    value={formData.date_time}
                                                    onChange={(newValue) => handleDateTimeChange('date_time', newValue)}
                                                    sx={{ width: '100%' }}
                                                    slotProps={{
                                                        textField: {
                                                            error: !formData.date_time,
                                                            helperText: submitted && !formData.date_time ? 'Date field is required' : '',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                        <Grid item xs={12} md={6}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="fuelReceipt_img"
                                                    name="fuelReceipt_img"
                                                    label="Receipt Images"
                                                    className='accessory_img'
                                                    type="file"
                                                    key={formData.fuelReceipt_img_url}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    size='small'
                                                    inputProps={{ accept: 'image/*,.pdf,.doc,.docx,.application/msword,.application/vnd.openxmlformats-officedocument.wordprocessingml.document' }}
                                                    autoComplete="images"
                                                    onChange={(e) => handleDocumentChange(e, "fuelReceipt_img")}
                                                />
                                                <Error current_key="images" />
                                            </Grid>
                                            {formData?.fuelReceipt_img_url !== '' && formData?.fuelReceipt_img_type === "image" &&
                                                <Grid item xs={12} className='imageDiv'>
                                                    <div key={formData?.fuelReceipt_img_url}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => handleImageClick(formData?.fuelReceipt_img_url)}>
                                                        <img src={formData.fuelReceipt_img_url} alt={`receipt-img`} className='preview-img' />
                                                    </div>
                                                </Grid>
                                            }
                                            {formData.fuelReceipt_img_type === "pdf" &&
                                                formData.fuelReceipt_img_url !== "" && (
                                                    <Grid item xs={12} className="pdf-icon">
                                                        <PictureAsPdfIcon /> Fuel Receipt
                                                    </Grid>
                                                )}
                                            {formData.fuelReceipt_img_type === "word" &&
                                                formData.fuelReceipt_img_url !== "" && (
                                                    <Grid item xs={12} className="pdf-icon">
                                                        <DescriptionIcon /> Aadhar Card
                                                    </Grid>
                                                )}
                                            {formData.fuelReceipt_img_type !== null && formData.fuelReceipt_img && (
                                                <Button
                                                    style={{ textAlign: "center" }}
                                                    onClick={() => {
                                                        setFormData({
                                                            ...formData,
                                                            fuelReceipt_img: null,
                                                            fuelReceipt_img_url: "",
                                                            fuelReceipt_img_type: "",

                                                        });
                                                        const thumbnailElement = document.getElementById(
                                                            "fuelReceipt_img"
                                                        ) as HTMLInputElement | null;
                                                        if (thumbnailElement) {
                                                            thumbnailElement.value = "";
                                                        }
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="save-btn"
                                                style={{ marginRight: '10px' }}
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className="close-btn"
                                                onClick={() => handleModel(false)}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </Container>
            <ImagePreviewDialog
                open={openDialog}
                onClose={handleCloseDialog}
                image={selectedImage}
            />
        </>
    );
};

export default memo(EditFuelTransaction);