import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Container,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  FormHelperText,
  Modal,
  Backdrop,
  Fade,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setError,
  useCreateShiftMutation,
  useEditShiftMutation,
  useGetShiftByIdQuery,
  useGetSitesDetailQuery,
} from "../../redux-services";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ShiftNameList, ShiftNameOptions } from "../../ constants";
import Error from "../../components/ErrorBox/Error";

export interface UserState {
  shift_name: string;
  site_id: any;
  isSiteChange: boolean;
  site_name: any;
  from_time: any;
  to_time: any;
}
interface FormErrors {
  site_id?: string;
  shift_name?: string;
  from_time?: string;
  to_time?: string;
}
const AddEditShift: FC<{
  open: boolean;
  handleModel: any;
  siteInfo: { id: string; name: string };
  id: string;
}> = ({ open, handleModel, siteInfo, id }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };
  // const navigate = useNavigate();
  // const { id, siteId } = useParams();
  console.log(id, "asdsfsdfsdfs");
  const isEditPage = !!id;
  const intialState = {
    shift_name: "",
    from_time: null,
    site_id: siteInfo.id,
    site_name: siteInfo.name,
    isSiteChange: false,
    to_time: null,
  };
  let dispatch = useDispatch();
  // let location = useLocation();
  const [formData, setFormData] = useState<UserState>(intialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const SitesData = useGetSitesDetailQuery();
  const [createShift, result] = useCreateShiftMutation();
  const [editShift, editResult] = useEditShiftMutation();
  const { data: ShiftData } = useGetShiftByIdQuery(id, {
    skip: !id,
  });
  useEffect(() => {
    if (ShiftData && ShiftData?.status === 200) {
      let data = ShiftData?.response;
      const site = SitesData?.data?.response?.find(
        (site: any) => site._id === ShiftData.response.site_id
      );
      const fromTime = dayjs(data?.from_time, "h:mm A");
      const toTime = dayjs(data?.to_time, "h:mm A");
      setFormData({
        ...formData,
        site_id: data?.site_id ?? "",
        site_name: site?.name,
        shift_name: data?.shift_name ?? "",
        from_time: fromTime ?? "",
        to_time: toTime ?? "",
      });
    }
    return () => {
      dispatch(setError([]));
    };
  }, [ShiftData, SitesData, dispatch]);

  if (result.isSuccess && result.data.status === 200) {
    // navigate(`../admin/organization/sites/${formData?.site_id}`);
    handleModel(false);
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    // navigate(`../admin/organization/sites/${formData?.site_id}`);
    handleModel(false);
  }
  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.trimStart(),
    }));

    const updatedFormData = { ...formData, [name]: value };
    const errors = validate(updatedFormData);

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name as keyof UserState],
    }));
  };

  // const selectSitesChange = (event: SelectChangeEvent<string>) => {
  //   event.preventDefault();
  //   const { value } = event.target;

  // const selectedSite = SitesData?.data?.response.find(
  //   (site: any) => site._id === value
  // );

  // if (!selectedSite) return;

  // // Update formData only when editing a shift
  // const currentSiteId = ShiftData?.response?.site_id;

  // setFormData((prevFormData) => ({
  //   ...prevFormData,
  //   site_id: selectedSite._id,
  //   site_name: selectedSite.name,
  //   isSiteChange: currentSiteId !== selectedSite._id,
  // }));
  // };

  const validate = (formData: UserState) => {
    const errors: Partial<UserState> = {};

    if (!formData.shift_name.trim()) {
      errors.shift_name = "Shift Name is required!";
    }

    // if (!formData.site_id.trim()) {
    //   errors.site_id = "Site Name is required!";
    // }

    if (!formData.from_time) {
      errors.from_time = "From time is required!";
    }
    if (!formData.to_time) {
      errors.to_time = "To time is required!";
    }

    return errors;
  };

  const handleCancel = useCallback(() => {
    // if (isEditPage) {
    //   navigate(`../admin/organization/sites/${formData?.site_id}`);
    // } else {
    //   navigate(`../admin/organization/sites/${siteId}`);
    // }
    handleModel(false);
  }, [handleModel]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const fromTime =
      formData.from_time instanceof Object
        ? new Date(formData.from_time).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
        : formData.from_time;
    const toTime =
      formData.to_time instanceof Object
        ? new Date(formData.to_time).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
        : formData.to_time;

    const checkError = validate(formData);
    if (Object.values(checkError)?.length > 0) {
      setFormErrors(checkError);
    } else {
      setFormErrors({});
      dispatch(setError([]));
      let formattedData = {
        shift_name: formData?.shift_name ?? "",
        site_id: formData?.site_id ?? "",
        from_time: fromTime ?? "",
        to_time: toTime ?? "",
        id: id ?? null,
      };
      id ? editShift(formattedData) : createShift(formattedData);
    }
  };
  // const breadcrumbs = [
  //   { name: "Dashboard", to: "/admin/dashboard" },
  //   { name: "Shift-List", to: "/admin/shift" },
  //   {
  //     name: id ? "Edit-Shift" : "Add-Shift",
  //     to: id ? `/admin/shift/edit/${id}` : "/admin/shift/add",
  //   },
  // ];
  console.log(formData, "fsdfdsgsdgsf");
  const handleFromTimeChange = (fieldName: string, value: any) => {
    const formattedTime = new Date(value).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: formattedTime,
    }));
    const errors = validate({ ...formData, [fieldName]: formattedTime });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errors[fieldName as keyof UserState],
    }));
  };

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>

<Fade in={open}>
          <Box sx={style} className="form-outers karuModal">
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                id="transition-modal-title"
                variant="h5"
                className="inner-headings"
                component="h2"
              >
                {isEditPage ? "Edit Shift" : "Add Shift"}
              </Typography>
        {/* <Container maxWidth={false} className="p-0">
          <Card elevation={3} className="form-outers">
            {/* <CardHeader
              className="inner-headings"
              title={isEditPage ? "Edit Shift" : "Add Shift"}
            /> */} 

            <HighlightOffIcon
              className="closeicon"
              onClick={() => handleModel(false)}
            />
 </Box>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl
                        fullWidth
                        size="small"
                        error={!!formErrors.site_id}>
                        <InputLabel id="site_id_label">Sites Names*</InputLabel>
                        <Select
                          value={formData.site_name || ""}
                          label="Sites Name"
                          renderValue={() => formData.site_name || ""}
                          disabled></Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!formErrors.shift_name}>
                      <InputLabel id="shift_name_label">Shift Name*</InputLabel>
                      <Select
                        required
                        labelId="shift_name_label"
                        id="shift_name"
                        value={formData.shift_name}
                        onChange={handleSelectChange}
                        label="Shift Name"
                        name="shift_name"
                        autoComplete="shift_name">
                        {ShiftNameList.map((item: ShiftNameOptions) => (
                          <MenuItem value={item.name} key={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {formErrors.shift_name && (
                        <FormHelperText>{formErrors.shift_name}</FormHelperText>
                      )}
                      <Error current_key="shift_name" />
                    </FormControl>
                  </Grid>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className="custom-datepicker"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%",
                      }}>
                      <FormControl
                        fullWidth
                        size="small"
                        error={!!formErrors.from_time}>
                        <TimePicker
                          label="From Time"
                          value={formData.from_time}
                          name="from_time"
                          onChange={(newValue) =>
                            handleFromTimeChange("from_time", newValue)
                          }
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!formErrors.from_time,
                              helperText: formErrors.from_time,
                              size: "small",
                              required: true,
                            },
                          }}
                        />
                        <Error current_key="from_time" />
                      </FormControl>
                    </Grid>
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className="custom-datepicker"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%",
                      }}>
                      <FormControl
                        fullWidth
                        size="small"
                        error={!!formErrors.to_time}>
                        <TimePicker
                          label="To Time"
                          value={formData.to_time}
                          name="to_time"
                          onChange={(newValue) =>
                            handleFromTimeChange("to_time", newValue)
                          }
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!formErrors.to_time, // Highlight field in red if there is an error
                              helperText: formErrors.to_time, // Show validation message if any
                              size: "small",
                              required: true,
                            },
                          }}
                        />
                        <Error current_key="to_time" />
                      </FormControl>
                    </Grid>
                  </LocalizationProvider>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="save-btn"
                      onSubmit={handleSubmit}
                      style={{ marginRight: "10px" }}
                      disabled={Object.values(formErrors).some(
                        (error) => !!error
                      )}>
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="close-btn"
                      onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          {/* </Card> */}
        {/* </Container> */}
        </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default AddEditShift;
