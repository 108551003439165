import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Paper,
  Snackbar,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  debounce,
} from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { TableBodyElement } from "../../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../../types/CommonTypes";
import { SearchElement } from "../../../components/Search/Search";
import {
  useGetMachineInfoByIdQuery,
  useGetMachineLogsByIdQuery,
} from "../../../redux-services";

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "machine_name",
    sort: true,
    label: "Machinery Name",
  },
  {
    id: "user_name",
    sort: true,
    label: "User Name",
  },
  {
    id: "work_type",
    sort: true,
    label: "Work Item",
  },
  {
    id: "chainage",
    sort: true,
    label: "Chainage Distance",
  },
  {
    id: "measurement",
    sort: true,
    label: "Measurement",
  },
  {
    id: "site_name",
    sort: true,
    label: "Site Name",
  },
  {
    id: "shift_name",
    sort: true,
    label: "Shift Name",
  },
  {
    id: "createdAt",
    sort: true,
    label: "Date",
  }
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const ShiftLogs = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  let navigate = useNavigate();
  let query = useLocation();
  const { id, siteId } = useParams<{ id: string; siteId: string }>();
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [shiftLogsList, setShiftLogsList] = useState<PageMeta>(initialState);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { data, refetch } = useGetMachineLogsByIdQuery({
    per_page: shiftLogsList.per_page ?? rowsPerPage,
    page: query.state ?? shiftLogsList.page,
    order: shiftLogsList.order ?? order,
    order_by: shiftLogsList.order_by ?? orderBy,
    searchText: shiftLogsList.searchText ?? search,
    site_id:siteId,
    id: id ?? "",
  });
  const { data: getData } = useGetMachineInfoByIdQuery(id, {
    skip: !id,
  });

  useEffect(() => {
    if (getData?.status === 200) {
      const getDataResponse = getData?.response;
      let data = getDataResponse;
      setIsActive(data.is_active);
    }
  }, [getData]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setShiftLogsList({
      ...shiftLogsList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setShiftLogsList({ ...shiftLogsList, page: newPage + 1 });

    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setShiftLogsList({
      ...shiftLogsList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setShiftLogsList({
        page: 1,
        per_page: shiftLogsList.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, []);

  return (
    <>
      <div>
        <Paper className="form-outers">
          <Toolbar>
            <Typography
              sx={{ flex: "1 1 40%" }}
              variant="h5"
              id="tableTitle"
              className="inner-headings"
              component="div">
              Machine Logs List
            </Typography>
            <div className="flex-boxinner">
              <Typography sx={{ flex: "1 1 auto" }}>
                <SearchElement
                  searchFn={searchHandler}
                  searchTag={"Search Shift Logs..."}
                />
              </Typography>
              <Snackbar
                open={snackbarOpen && !isActive}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message="Please activate this machine to add work logs."
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ marginTop: "140px" }}
              />
            </div>
          </Toolbar>
          <StyledEngineProvider>
            <TableContainer component={Paper} className="form-outers">
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
                className="table-outers">
                <TableHead>
                  <TableRow>
                    <TableHeadElement
                      setHead={tableColumns ?? []}
                      order={order}
                      orderBy={orderBy}
                      sortFn={(event, id: string) =>
                        createSortHandler(event, id as keyof SortingData)
                      }
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableBodyElement
                    selectedColumns={[
                      "machine_name",
                      "user_name",
                      "work_type",
                      "chainage",
                      "measurement",
                      "site_name",
                      "shift_name",
                      "createdAt",
                    ]}
                    setData={data?.response.data ? data?.response.data : []}
                    pageData={{
                      limit:
                        rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                      page: data?.response.page as number,
                    }}
                  />
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={data?.response.total ?? 0}
                page={data?.response.page ?? 0}
                rowsPerPageOptions={[20, 50, 100]}
                onPageChange={handleChangePage}
                rowsPerPage={
                  rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                }
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </StyledEngineProvider>
        </Paper>
      </div>
    </>
  );
};

export default memo(ShiftLogs);
