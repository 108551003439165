import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    StyledEngineProvider,
    TableContainer,
    Paper,
} from "@mui/material";
import CollapsibleTable from '../../../../../components/TableBody/CollapsibleTable';
import { useState } from 'react';
import EditAttendance from '../../SiteEmployees/EditAttendance';
import AddEditService from '../../../../MachineInfo/Machine Service Records/AddEditService';
import EditFuelTransaction from '../../FuelStorage/EditFuelTransaction';

const EarningExpenditureReport = (props: any) => {

    const { earning, expense } = props;


    const [showModal, setShowModal] = useState(false);
    const [workLogState, setWorkLogState] = useState({
        id: '',
        work_item_id: '',
    });
    const [employeeDetail, setEmployeeDetail] = useState({
        id: '',
        site_id: '',
    });
    const [machineService, setMachineService] = useState({
        id: '',
        machine_id: "",
        site_id: "",
        machine_name: ""
    });
    const [fuelTransaction, setFuelTransaction] = useState({
        id: '',
    });

    const handleEditAttendance = (id: string, siteId: string) => {
        setEmployeeDetail({ id: id, site_id: siteId });
        setShowModal(true);
    }

    const handleEditService = (id: string, machineId: string, siteId: string, machineName: string) => {
        setMachineService({ id: id, machine_id: machineId, site_id: siteId, machine_name: machineName });
        setShowModal(true);
    };

    const handleEditFuelTransaction = (id: string) => {
        setFuelTransaction({ id: id });
        setShowModal(true);
    }

    return (
        <div >
            <Accordion style={{ background: "#15365557" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>EARNING (RS. {earning?.trips_earning})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledEngineProvider>
                        <TableContainer component={Paper} className="form-outers">
                            <Table aria-label="collapsible table">
                                <TableHead className='topheader'>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="left">Machine Name</TableCell>
                                        <TableCell align="left">Collection</TableCell>
                                    </TableRow>
                                </TableHead>
                                {earning?.data?.length > 0 && earning?.data?.map((item: any) => (
                                    <TableBody className='tableInnerPanel'>
                                        <CollapsibleTable
                                            selectedColumns={[
                                                "machine_name",
                                                "collection",
                                            ]}
                                            data={item}
                                            subTableData={[
                                                {
                                                    title: 'Machine Work Log',
                                                    key: "work_log",
                                                    class: "machine_work_log_detail",
                                                    data: [
                                                        { title: 'Trips', key: "trips" },
                                                        { title: 'Collection', key: "collection" },
                                                        { title: 'Date', key: "date" }
                                                    ],
                                                }
                                            ]}
                                        />
                                    </TableBody>
                                ))}
                            </Table>
                        </TableContainer>
                    </StyledEngineProvider>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{ background: "#15365557" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Expenditure (RS. {expense?.total_expense})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Accordion className="Salary-Expenditure" style={{ background: "#15365557" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>Salary Expenditure (RS. {expense?.salary?.salary_expense ?? 0})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <StyledEngineProvider>
                                {expense?.salary?.data?.length > 0 ?
                                    <TableContainer component={Paper} className="form-outers">
                                        <Table aria-label="collapsible table">
                                            <TableHead className='topheader'>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell align="left">User Name</TableCell>
                                                    <TableCell align="left">Contact</TableCell>
                                                    <TableCell align="left">Salary (Rupees)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {expense?.salary?.data?.length > 0 && expense?.salary?.data?.map((item: any) => (
                                                <TableBody className='tableInnerPanel'>
                                                    <CollapsibleTable
                                                        selectedColumns={[
                                                            "username",
                                                            "contact",
                                                            "salary",
                                                        ]}
                                                        data={item}
                                                        subTableData={[
                                                            {
                                                                title: 'Daily Attendance Logs',
                                                                key: "daily_logs",
                                                                class: "daily_logs_detail",
                                                                data: [
                                                                    { title: 'Trips', key: "status" },
                                                                    { title: 'Date', key: "date" }
                                                                ],
                                                                isEdit: true,
                                                                editFn: (id: string, siteId: string) => { handleEditAttendance(id, siteId) }
                                                            }
                                                        ]}
                                                    />
                                                </TableBody>
                                            ))}
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography>No Record Found</Typography>
                                }
                            </StyledEngineProvider>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="Machine-Expenditure" style={{ background: "#15365557" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>Machine Expenditure (RS. {expense?.machine?.machine_expense ?? 0})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <StyledEngineProvider>
                                {expense?.machine?.data?.length > 0 ?
                                    <TableContainer component={Paper} className="form-outers">
                                        <Table aria-label="collapsible table">
                                            <TableHead className='topheader'>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell align="left">Machine Name</TableCell>
                                                    <TableCell align="left">Rent Charges (Rupees)</TableCell>
                                                    <TableCell align="left">Loan Charges (Rupees)</TableCell>
                                                    <TableCell align="left">Machine Service Amount (Rupees)</TableCell>
                                                    <TableCell align="left">Total Machine Expense (Rupees)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {expense?.machine?.data?.length > 0 && expense?.machine?.data?.map((item: any) => (
                                                <TableBody className='tableInnerPanel'>
                                                    <CollapsibleTable
                                                        selectedColumns={[
                                                            "machine_name",
                                                            "rent_charges",
                                                            "loan_charges",
                                                            "machine_service_amount",
                                                            "machine_charges"
                                                        ]}
                                                        data={item}
                                                        subTableData={[
                                                            {
                                                                title: 'Service Record',
                                                                key: "service_record",
                                                                class: "service_record_detail",
                                                                data: [
                                                                    { title: 'Machine Name', key: "machine_name" },
                                                                    { title: 'Service Type', key: "service_type" },
                                                                    { title: 'Amount (Rupees)', key: "amount" },
                                                                    { title: 'Date', key: "service_date" }
                                                                ],
                                                                isEdit: true,
                                                                editFn: (id: string, machineId: string, siteId: string, machineName: string) => { handleEditService(id, machineId, siteId, machineName) }
                                                            }
                                                        ]}
                                                    />
                                                </TableBody>
                                            ))}
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography>No Record Found</Typography>
                                }
                            </StyledEngineProvider>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="Fuel-Expenditure" style={{ background: "#15365557" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>Fuel Expenditure (RS. {expense?.fuel?.fuel_expense ?? 0})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <StyledEngineProvider>
                                {expense?.fuel?.data?.length > 0 ?
                                    <TableContainer component={Paper} className="form-outers">
                                        <Table aria-label="collapsible table">
                                            <TableHead className='topheader'>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell align="left">Fuel Type</TableCell>
                                                    <TableCell align="left">Current Fuel Quantity</TableCell>
                                                    <TableCell align="left">Total Amount (Rupees)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {expense?.fuel?.data?.length > 0 && expense?.fuel?.data?.map((item: any) => (
                                                <TableBody className='tableInnerPanel'>
                                                    <CollapsibleTable
                                                        selectedColumns={[
                                                            "fuel_type",
                                                            "current_quantity",
                                                            "total_amount"
                                                        ]}
                                                        data={item}
                                                        subTableData={[
                                                            {
                                                                title: 'Fuel Transaction',
                                                                key: "transactions",
                                                                class: "fuel_transaction_detail",
                                                                data: [
                                                                    { title: 'Refill Qty.', key: "refill_quantity" },
                                                                    { title: 'Price Per Unit (Rupees)', key: "price_per_unit" },
                                                                    { title: 'Total Fuel Price (Rupees)', key: "total_fuel_price" },
                                                                    { title: 'Date', key: "date" }
                                                                ],
                                                                isEdit: true,
                                                                editFn: (id: string) => { handleEditFuelTransaction(id) }
                                                            }
                                                        ]}
                                                    />
                                                </TableBody>
                                            ))}
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography>No Record Found</Typography>
                                }
                            </StyledEngineProvider>
                        </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
            </Accordion>
            {showModal && employeeDetail?.id !== '' &&
                <EditAttendance
                    open={showModal}
                    handleModel={() => { setShowModal(false); setEmployeeDetail({ id: "", site_id: "" }); props.refetchEarningExpense(); }}
                    siteInfo={{ id: employeeDetail.site_id, name: "", }}
                    id={employeeDetail?.id}
                />
            }

            {showModal && machineService?.id !== '' &&
                <AddEditService
                    id={machineService?.id}
                    mchnData={[{ _id: machineService.id, machine_name: machineService?.machine_name }]}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setMachineEntryEditId={() => { setMachineService({ id: "", machine_name: "", machine_id: "", site_id: "" }); props.refetchEarningExpense(); }}
                    paramId={machineService?.machine_id}
                    siteId={machineService?.site_id}
                />
            }
            {showModal && (
                <EditFuelTransaction
                    open={showModal}
                    handleModel={() => { setShowModal(false); setFuelTransaction({ id: "" }); props.refetchEarningExpense(); }}
                    handleViewModal={() => console.log(";")}
                    transactionId={fuelTransaction?.id}
                />
            )}
        </div>
    )
}

export default EarningExpenditureReport