import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Paper,
  Snackbar,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  debounce,
} from "@mui/material";
import { TableBodyElement } from "../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../types/CommonTypes";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import { SearchElement } from "../../components/Search/Search";
import {
  useDeleteShiftMutation,
  useGetSitesByIdQuery,
  useShiftListQuery,
  useSitesListByOrgIdQuery,
  useUpdateShiftStatusMutation,
} from "../../redux-services";
import AddEditShift from "./AddEditShift";
import BackButtonListener from "../../components/BackButton/BackButton";

interface MachineType {
  _id: string;
  status: boolean;
}
const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "site_name",
    sort: true,
    label: "Site Name",
  },
  {
    id: "shift_name",
    sort: true,
    label: "Shift",
  },
  {
    id: "from_time",
    sort: true,
    label: "From Time",
  },
  {
    id: "to_time",
    sort: true,
    label: "To Time",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "15%" },
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

interface ShiftProps {
  siteDetail: { id: string; name: string };
}

const Shift: React.FC<ShiftProps> = ({ siteDetail }) => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  let navigate = useNavigate();
  let query = useLocation();
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [activeMachineId, setActiveMachineId] = useState<string>("");
  const [machineStatus, setMachineStatus] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [sitesList] = useState<PageMeta>(initialState);
  const [filteredData] = useState(null);

  const [shiftList, setShiftList] = useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");
  const [, setNames] = useState<string[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>('');

  const { data } = useShiftListQuery({
    site_id: siteDetail.id ?? "",
    per_page: shiftList.per_page ?? rowsPerPage,
    page: query.state ?? shiftList.page,
    order: shiftList.order ?? order,
    order_by: shiftList.order_by ?? orderBy,
    searchText: shiftList.searchText ?? search,
  });

  const { data: sitesData } = useSitesListByOrgIdQuery({
    order: sitesList.order ?? order,
    order_by: sitesList.order_by ?? orderBy,
  });

  const [deleteShift] = useDeleteShiftMutation();
  const [updateActiveStatus] = useUpdateShiftStatusMutation();

  const { data: getData } = useGetSitesByIdQuery(siteDetail.id, {
    skip: !siteDetail.id,
  });


  useEffect(() => {
    if (getData?.status === 200) {
      let data = getData.response ?? {};
      console.log(data, "Dataa123");
      setIsButtonDisabled(data.is_deactivate);
      setIsActive(data.is_active);
    }
  }, [getData]);
  useEffect(() => {
    if (sitesData?.response?.data) {
      const extractedNames = sitesData?.response?.data?.map(
        (item: any) => item.name
      );
      setNames(extractedNames);
    }
  }, [sitesData]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setShiftList({
      ...shiftList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setShiftList({ ...shiftList, page: newPage + 1 });

    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setShiftList({
      ...shiftList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const handleModel = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
}, []);

  const handleShowMessage = () => {
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setShiftList({
        page: 1,
        per_page: shiftList.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, [shiftList.per_page]);

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);

      deleteShift(Id);
    } else {
      setIsClose(res);
    }
  };
  const handleToggleActive = (id: string) => {
    const machine = data?.response.data.find(
      (machine: MachineType) => machine._id === id
    );
    if (machine) {
      setActiveMachineId(id);
      setIsConfirmationOpen(true);
      setMachineStatus(machine.is_active);
    }
  };
  const handleConfirmationResponse = (confirmed: boolean) => {
    if (confirmed) {
      const newStatus = !machineStatus;
      updateActiveStatus({ id: activeMachineId, status: newStatus });
    }
    setActiveMachineId("");
    setIsConfirmationOpen(false);
  };
  const tableData = filteredData ? filteredData : data?.response.data || [];

  let actionMessage = "";

  if (activeMachineId) {
    actionMessage = `Are you sure you want to ${
      machineStatus ? "deactivate" : "activate"
    } this Shift?`;
  }

  return (
    <div>
      <BackButtonListener/>
      <Paper className="form-outers">
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 40%" }}
            variant="h5"
            id="tableTitle"
            className="inner-headings"
            component="div">
            Shift List
          </Typography>
         
          <div className="flex-boxinner">
            <Typography sx={{ flex: "1 1 auto", marginRight: "0px" }}>
              <SearchElement
                searchFn={searchHandler}
                searchTag={"Search Shift..."}
              />
            </Typography>
            {isButtonDisabled ? null : (
              <Tooltip title="Add Shift" arrow className="me-1 mt-1">
                <Button
                  className="secondary-btn"
                  onClick={() => {
                    handleShowMessage();
                    setOpen(true);
                    setEditId('');
                  }}
                  type="submit"
                  variant="contained"
                  color="primary">
                  Add Shift
                </Button>
              </Tooltip>
            )}
            <Snackbar
              open={snackbarOpen && !isActive}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
              message="Please activate this site to add Shift."
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              style={{ marginTop: "140px" }}
            />
          </div>
        </Toolbar>
        <StyledEngineProvider>
          <TableContainer component={Paper} className="form-outers">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              className="table-outers">
              <TableHead>
                <TableRow>
                  <TableHeadElement
                    setHead={tableColumns ? tableColumns : []}
                    order={order}
                    orderBy={orderBy}
                    sortFn={(event, id: string) =>
                      createSortHandler(event, id as keyof SortingData)
                    }
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableBodyElement
                  selectedColumns={[
                    "site_name",
                    "shift_name",
                    "from_time",
                    "to_time",
                  ]}
                  setData={tableData}
                  editFn={(id: string) =>{
                    setOpen(true);
                    setEditId(id);
                  }
                    
                  }
                  deleteFn={(id) => {
                    setIsClose(true);
                    setId(id);
                  }}
                  isActivefn={(id) => handleToggleActive(id)}
                  pageData={{
                    limit:
                      rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: data?.response.page as number,
                  }}
                />
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={data?.response.total ?? 0}
              page={data?.response.page ?? 0}
              rowsPerPageOptions={[20, 50, 100]}
              onPageChange={handleChangePage}
              rowsPerPage={
                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </StyledEngineProvider>
      </Paper>
      <ConfirmBox
        title={`Shift ${
          machineStatus ? "Deactivate" : "Activate"
        } Confirmation`}
        message={actionMessage}
        open={isConfirmationOpen}
        onClose={handleConfirmationResponse}
      />
      <ConfirmBox
        title="Shift Delete Confirmation"
        message="Are you sure, you want to delete this Shift?"
        open={isClose}
        onClose={confirmBoxClose}
      />
      {open && isActive &&
                <AddEditShift
                    open={open}
                    handleModel={handleModel}
                    siteInfo={siteDetail}
                    id={editId}
                />
            }
    </div>
  );
};

export default Shift;
