import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
    Button,
    Paper,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";
import { TableBodyElement } from '../../../../../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../../../../../components/TableBody/TableHeadElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../../../../types/CommonTypes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmBox from '../../../../../../components/ConfirmBox/ConfirmBox';
import { SearchElement } from '../../../../../../components/Search/Search';
import { useDeleteVehicleInfoMutation, useVehicleInfoListWithSiteQuery } from '../../../../../../redux-services';
import VehicleAddEditModal from './VehicleAddEditModal';
import BackButtonListener from '../../../../../../components/BackButton/BackButton';

const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "vehicle_reg_number",
        sort: true,
        label: "Vehicle Reg. Number",
    },
    {
        id: "fleet_id",
        sort: true,
        label: "Fleet ID"
    },
    {
        id: "engine_number",
        sort: true,
        label: "Engine Number"
    },
    {
        id: "fuel_type",
        sort: true,
        label: "Fuel Type"
    },
    {
        id: "vehicle_type",
        sort: true,
        label: "Vehicle Type"
    },
    {
        id: "is_scv_ev",
        sort: true,
        label: "SCV EV"
    },
    {
        id: "lob",
        sort: true,
        label: "Lob"
    },
    {
        id: "req_by",
        sort: true,
        label: "Req By"
    },
    {
        id: "vins_id",
        sort: true,
        label: "Vins ID"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const TrackerVehicleInfo: FC<{ siteID: any }> = ({ siteID }) => {
    const site_id = siteID;
    const [siteid, setSiteId] = useState(null)
    useEffect(() => {
        if (siteID) {
            setSiteId(siteID)
        }
    }, [siteID, site_id])
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,

    };
    let navigate = useNavigate();
    let query = useLocation();

    const { id } = useParams();


    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [vehicleInfoList, setVehicleInfoList] = useState<PageMeta>(initialState);
    const [isClose, setIsClose] = useState<boolean>(false);
    const [Id, setId] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');

    const { data } = useVehicleInfoListWithSiteQuery({
        per_page: vehicleInfoList.per_page ?? rowsPerPage,
        page: query.state ?? vehicleInfoList.page,
        order: vehicleInfoList.order ?? order,
        order_by: vehicleInfoList.order_by ?? orderBy,
        searchText: vehicleInfoList.searchText ?? search,
        id: id,
    });

    var dt: any;

    const dtt = data?.response?.data?.filter((vehicleData: any) => vehicleData.fleet_tracker_id == id)

    if (dtt) {
        dt = dtt && dtt[0]?.vehicle_info
        dt = dt?.filter((vehicleInfo: any) => vehicleInfo?.is_delete === false);
    }
    const [deleteVehicleInfo] = useDeleteVehicleInfoMutation();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setVehicleInfoList({
            ...vehicleInfoList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setVehicleInfoList({ ...vehicleInfoList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setVehicleInfoList({
            ...vehicleInfoList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setVehicleInfoList({
                page: 1,
                per_page: vehicleInfoList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteVehicleInfo(Id);
        } else {
            setIsClose(res);
        }
    };

    const handleModel = useCallback((isOpen: boolean) => {
        setOpen(isOpen);
    }, []);

    return (
        <>
        <BackButtonListener />
        <div className='table'>
            <Paper className="table-outers">
                <Toolbar>
                    <Typography
                        sx={{ flex: '1 1 40%' }}
                        variant="h5"
                        id="tableTitle"
                        component="div"
                        className="inner-headings"
                    >
                        Vehicle List
                    </Typography>

                    <div className="flex-boxinner">
                        <Typography
                            sx={{ flex: '1 1 auto' }}
                        >
                            <SearchElement
                                searchFn={searchHandler}
                                searchTag={`Search Vehicle...`}
                            />

                        </Typography>
                        <Tooltip title={`Add Vehicle`} arrow className="me-1 mt-1">
                            <Button className="secondary-btn" onClick={() => { setOpen(true); setEditId('') }} type="submit" variant="contained" color="primary">Add Vehicle</Button>
                        </Tooltip>
                    </div>
                </Toolbar>
                <StyledEngineProvider>
                    <TableContainer component={Paper} className="table-outers">
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small" aria-label="a dense table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableHeadElement
                                        setHead={tableColumns ? tableColumns : []}
                                        order={order}
                                        orderBy={orderBy}
                                        sortFn={(event: any, id: string) =>
                                            createSortHandler(event, id as keyof SortingData)
                                        }
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableBodyElement
                                    selectedColumns={[
                                        "vehicle_reg_number",
                                        "fleet_id",
                                        "engine_number",
                                        "fuel_type",
                                        "vehicle_type",
                                        "is_scv_ev",
                                        "lob",
                                        "req_by",
                                        "vins_id",
                                    ]}
                                    setData={dt ? dt : []}
                                    editFn={(id: any) => {
                                        setOpen(true);
                                        setEditId(id);
                                    }}
                                    deleteFn={(id: string) => {
                                        setIsClose(true);
                                        setId(id);
                                    }}
                                    pageData={{
                                        limit:
                                            rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                        page: data?.response?.page as number,
                                    }}
                                />
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={data?.response?.total ?? 0}
                            page={data?.response?.page ?? 0}
                            rowsPerPageOptions={[20, 50, 100]}
                            onPageChange={handleChangePage}
                            rowsPerPage={
                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                            }
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </StyledEngineProvider>
            </Paper>
            <ConfirmBox
                title="Vehicle Delete Confirmation"
                message="Are you sure, you want to delete this Vehicle?"
                open={isClose}
                onClose={confirmBoxClose}
            />
            {open &&
                <VehicleAddEditModal
                    open={open}
                    handleModel={handleModel}
                    editId={editId}
                    siteID={siteid}
                />
            }
        </div>
        </>
    )
}

export default TrackerVehicleInfo