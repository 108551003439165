import React, { FC, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Modal,
  Backdrop,
  Fade,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setError,
  useCreateMachineInfoMutation,
  useEditMachineInfoMutation,
  useGetSitesDetailQuery,
  useGetMachineByOrgIdQuery,
} from "../../../redux-services";
import Error from "../../../components/ErrorBox/Error";
import ReusableAddEditMachineInfo from "../ReusableAddEditMahineInfo";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
export interface UserState {
  site_id: any;
  machine_id: any;
  machine_type: any;
  [key: string]: any;
  company: string;
  machine_model: string;
  number_plate: string;
  purchase_date: string;
  tank_capacity: number;
  insurance_amount: number;
  volume_capacity: number;
  expiration_date: string;
  isSiteChange: boolean;
  isMachineChange: boolean;
  is_rented: boolean;
  is_loanable: boolean;
  monthly_installment: number | any;
  financer_name: string;
  loan_duration: number;
  rented_amount: number | any;
  security_amount: number | any;
  measurement_unit: string;
}
interface FormErrors {
  machine_type?: string;
  company?: string;
  machine_model?: string;
  number_plate?: string;
  purchase_date?: string;
  tank_capacity?: string;
  volume_capacity?: string;
  insurance_amount?: string;
  expiration_date?: string;
  financer_name?: string;
  loan_duration?: string;
  monthly_installment?: string;
  rented_amount?: string;
  security_amount?: string;}
const AddEditMachineDetail: FC<{
  open: boolean;
  handleModel: any;
  machineData?: any | undefined;
}> = ({ open, handleModel, machineData }) => {
  const { id } = useParams();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };

  const intialState = {
    site_id: "",
    machine_id: "",
    machine_type: "",
    company: "",
    machine_model: "",
    number_plate: "",
    expiration_date: "",
    purchase_date: "",
    tank_capacity: 0,
    volume_capacity: 0,
    isSiteChange: false,
    isMachineChange: false,
    is_rented: false,
    is_loanable: false,
    financer_name: "",
    monthly_installment: 0,
    loan_duration: 0,
    insurance_amount: 0,
    rented_amount: 0,
    security_amount: 0,
    measurement_unit: "",
  };
  let dispatch = useDispatch();
  const [formData, setFormData] = useState<UserState>(intialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [createMachineInfo, result] = useCreateMachineInfoMutation();
  const [editMachineInfo, editResult] = useEditMachineInfoMutation();
  const SitesData = useGetSitesDetailQuery();
  const orgId = machineData?.machinetypeData?.organization_id ?? "";
  const machineTypeData = useGetMachineByOrgIdQuery(orgId, { skip: !orgId });
  const [showLoanCheckBox, setShowLoanCheckBox] = useState(true);
  const [showRentedCheckBox, setShowRentedCheckBox] = useState(true);
  const [existingNumberPlates, setExistingNumberPlates] = useState<string[]>(
    []
  );
  useEffect(() => {
    if (formData.is_rented) {
      setShowLoanCheckBox(!formData.is_rented);
    } else if (formData.is_loanable) {
      setShowRentedCheckBox(!formData.is_loanable);
    } else {
      setShowRentedCheckBox(true);
      setShowLoanCheckBox(true);
    }
  }, [formData.is_rented, formData.is_loanable]);
  useEffect(() => {
    if (machineData) {
      let data = machineData;
      let machineTypeData = data?.machine_type;
      machineTypeData = {
        _id: machineTypeData?._id?.length === 0 ? "" : machineTypeData?._id,
        name: machineTypeData?.name?.length === 0 ? "" : machineTypeData?.name,
      };
      let siteIdData = data?.siteData[0];
      let machineIdData = data?.machinetypeData;

      siteIdData = {
        _id: siteIdData?._id?.length === 0 ? "" : siteIdData?._id,
        name: siteIdData?.name?.length === 0 ? "" : siteIdData?.name,
      };

      setExistingNumberPlates(data?.number_plates);

      setFormData({
        ...formData,
        machine_id: machineIdData ?? [],
        machine_name: machineIdData?._id ?? [],
        site_id: siteIdData ?? [],
        company: data?.company ?? "",
        machine_model: data?.machine_model ?? "",
        number_plate: data?.number_plate ?? "",
        purchase_date: data?.purchase_date ?? "",
        volume_capacity: data?.volume_capacity ?? 0,
        tank_capacity: data?.tank_capacity ?? 0,
        is_rented: data?.is_rented ?? false,
        is_loanable: data?.is_loanable ?? false,
        financer_name: data?.financer_name ?? "",
        monthly_installment: data?.monthly_installment ?? 0,
        loan_duration: data?.loan_duration ?? 0,
        rented_amount: data?.rented_amount ?? 0,
        security_amount: data?.security_amount ?? 0,
        measurement_unit: data?.machinetypeData?.measurement_unit ?? "",
      });
    }
    return () => {
      dispatch(setError([]));
    };
  }, [machineData]);

  if (result.isSuccess && result.data.status === 200) {
    handleModel(false);
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    handleModel(false);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const checkError = validate(formData);
    if (Object.values(checkError)?.length > 0) {
      setFormErrors(checkError);
    } else {
      setFormErrors({});
      dispatch(setError([]));
      const purchaseDateTimestamp = Math.floor(
        new Date(formData.purchase_date)?.getTime()
      );
      let formattedData = {
        machine_type: formData?.machine_id._id ?? "",
        company: formData?.company ?? "",
        machine_model: formData?.machine_model ?? "",
        number_plate: formData?.number_plate ?? "",
        purchase_date: purchaseDateTimestamp ?? "",
        volume_capacity: Number(formData?.volume_capacity),
        tank_capacity: Number(formData?.tank_capacity),
        site_id: formData.site_id._id !== "" ? formData?.site_id?._id : "",
        isSiteChange: formData?.isSiteChange,
        is_rented: formData?.is_rented ?? false,
        rented_amount: formData?.rented_amount ?? 0,
        is_loanable: formData?.is_loanable ?? false,
        financer_name: formData?.financer_name ?? "",
        monthly_installment: formData?.monthly_installment ?? 0,
        loan_duration: formData?.loan_duration ?? 0,
        security_amount: formData?.security_amount ?? 0,

        measurement_unit: formData?.measurement_unit ?? "",
        id: id ? id : null,
      };
      id ? editMachineInfo(formattedData) : createMachineInfo(formattedData);
    }
  };

  const validate = (formData: UserState) => {
    const errors: Partial<FormErrors> = {};
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const numberRegex = /^\d+$/;
    if (!formData.machine_model.trim()) {
      errors.machine_model = "Machine Model is required!";
    }
    if (!formData.company.trim()) {
      errors.company = "Company is required!";
    }
    if (regex.test(formData.company)) {
      errors.company = "Company cannot contain special character";
    }
    else if (numberRegex.test(formData.company)) {
      errors.company = "  Company cannot contain numbers"
    }
    if (regex.test(formData.company)) {
      errors.company = "Company cannot contain special character";
    }
    else if (numberRegex.test(formData.company)) {
      errors.company = "  Company cannot contain numbers"
    }
    if (existingNumberPlates.includes(formData.number_plate)) {
      errors.number_plate = "Number Plate already exists!";
    } else if (!/\d/.test(formData.number_plate)) {
      errors.number_plate = "Number Plate must contain at least one number";
    }
    if (!formData.purchase_date) {
      errors.purchase_date = "Purchase date is required";
    }
    if (regex.test(formData.machine_model)) {
      errors.machine_model = "Machine model cannot contain special characters";
    }
    if (formData.is_loanable) {
      if (!formData.financer_name.trim()) {
        errors.financer_name = "Financer name is required";
      }
      if (regex.test(formData.financer_name)) {
        errors.financer_name = "Financer name cannot contain special characters";
      } else if (numberRegex.test(formData.financer_name)) {
        errors.financer_name = "Financer name cannot contain numbers";
      }
      const loanDuration = Number(formData.loan_duration);
      const monthlyInstallment = Number(formData.monthly_installment);
      if (loanDuration === 0) {
        errors.loan_duration = "Loan duration must be greater than 0";
      }
      if (monthlyInstallment === 0) {
        errors.monthly_installment = "Monthly installment must be greater than 0";
      }
    }
    if (formData.is_rented) {
      const rentedAmount = Number(formData.rented_amount);
      const securityAmount = Number(formData.security_amount);
      if (rentedAmount === 0) {
        errors.rented_amount = "Rented Amount must be greater than 0";
      }
      if (securityAmount === 0) {
        errors.monthly_installment = "Security Amount must be greater than 0";
      }
    }
    return errors;
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (name === "is_rented" && checked) {
      setFormData((prevState) => ({
        ...prevState,
        is_rented: true,
        is_loanable: false,
      }));
    } else if (name === "is_loanable" && checked) {
      setFormData((prevState) => ({
        ...prevState,
        is_rented: false,
        is_loanable: true,
      }));
    } else {
      setFormData((prevState) => {
        const updatedState = {
          ...prevState,
          [name]: checked,
        };

        if (!checked && (name === "is_rented" || name === "is_loanable")) {
          updatedState.rented_amount = 0;
          updatedState.security_amount = 0;
          updatedState.financer_name = "";
          updatedState.loan_duration = 0;
          updatedState.monthly_installment = 0;
        }

        return updatedState;
      });
    }
  };
  const handleChange = (e: any) => {
    e.preventDefault();
    let { name, value } = e.target;
  
    if (name === "number_plate") {
      value = value.toLocaleUpperCase();
    }
    if (name === "company" && value.length > 0) {
      value = value[0].toUpperCase() + value.slice(1);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value === "" || isNaN(Number(value)) ? value.trimStart() : Number(value),
    }));
  
    const errors = validate({ ...formData, [name]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name as keyof FormErrors],
    }));
  };
  const selectSitesChange = (event: SelectChangeEvent | any) => {
    let userData = machineData?.siteData && machineData?.siteData[0];
    if (userData?._id === event.target.value?._id) {
      setFormData({
        ...formData,
        site_id: event.target.value as string,
        isSiteChange: false,
      });
    } else {
      setFormData({
        ...formData,
        site_id: event.target.value as string,
        isSiteChange: true,
      });
    }
  };

  const selectMachineTypeChange = (event: SelectChangeEvent | any) => {
    const selectedValue = event.target.value.name;
    const machineDetails = machineTypeData?.data?.response.find(
      (machine: { name: any }) => machine.name === selectedValue
    );

    let userData = machineData?.machinetypeData;
    if (userData?._id === event.target.value?._id) {
      setFormData({
        ...formData,
        machine_id: event.target.valueevent.target.value as string,
        isMachineChange: false,
        volume_capacity: 0,
        number_plate: "",
        tank_capacity: 0,
        measurement_unit: machineDetails?.measurement_unit || "",
      });
    } else {
      setFormData({
        ...formData,
        machine_id: event.target.value as string,
        isMachineChange: true,
        volume_capacity: 0,
        number_plate: "",
        tank_capacity: 0,
        measurement_unit: machineDetails?.measurement_unit || "",
      });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}>
      <Fade in={open}>
        <Box sx={style} className="form-outers karuModal">
          <Typography
            id="transition-modal-title"
            variant="h5"
            className="inner-headings"
            component="h2">
            {id ? "Edit Machinery" : "  "}
          </Typography>
          <HighlightOffIcon
            className="closeicon"
            onClick={() => handleModel(false)}
          />

          <Box className="modalBody">
            <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Sites Names
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.site_id}
                        label="Sites Name"
                        onChange={selectSitesChange}
                        renderValue={() => formData.site_id?.name}>
                        {SitesData &&
                          SitesData?.data?.response.map(
                            (item: any, index: number) => (
                              <MenuItem value={item} key={index}>
                                {item?.name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Machine Types*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.machine_id}
                        label="Machine Types"
                        required
                        onChange={selectMachineTypeChange}
                        renderValue={() => formData.machine_id?.name}>
                        {machineTypeData &&
                          machineTypeData?.data?.response.map(
                            (item: any, index: number) => (
                              <MenuItem value={item} key={index}>
                                {item?.name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    id="company"
                    label="Company"
                    name="company"
                    type="text"
                    size="small"
                    autoComplete="company"
                    value={formData.company}
                    onChange={handleChange}
                    error={!!formErrors.company}
                    helperText={formErrors.company}
                  />
                  <Error current_key="company" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    id="machine_model"
                    label="Machine Model"
                    name="machine_model"
                    type="text"
                    size="small"
                    autoComplete="machine_model"
                    value={formData.machine_model}
                    onChange={handleChange}
                    error={!!formErrors.machine_model}
                    helperText={formErrors.machine_model}
                  />
                  <Error current_key="machine_model" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    id="purchase_date"
                    label="Purchase Date"
                    name="purchase_date"
                    className="calendradate"
                    type="date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="purchase_date"
                    value={formData.purchase_date}
                    onChange={handleChange}
                    error={!!formErrors.purchase_date}
                    helperText={formErrors.purchase_date}
                  />
                  <Error current_key="purchase_date" />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.is_rented}
                        onChange={handleCheckboxChange}
                        name="is_rented"
                      />
                    }
                    label={
                      <Typography sx={{ color: "white" }}>
                        Rented Machine
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.is_loanable}
                        onChange={handleCheckboxChange}
                        name="is_loanable"
                      />
                    }
                    label={
                      <Typography sx={{ color: "white" }}>Loanable</Typography>
                    }
                  />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  {showRentedCheckBox && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.is_rented}
                          onChange={handleCheckboxChange}
                          name="is_rented"
                        />
                      }
                      label={
                        <Typography sx={{ color: "white" }}>Rented Machine</Typography>
                      } />
                  )}
                  {showLoanCheckBox && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.is_loanable}
                          onChange={handleCheckboxChange}
                          name="is_loanable"
                        />
                      }
                      label={
                        <Typography sx={{ color: "white" }}>Loanable</Typography>
                      } />
                  )}
                </Grid>
                {formData?.is_rented && (
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      required
                      id="rented_amount"
                      label="Rented Amount"
                      name="rented_amount"
                      type="number"
                      size="small"
                      inputProps={{
                        min: 1,
                      }}
                      autoComplete="rented_amount"
                      value={formData.rented_amount}
                      onChange={handleChange}
                      error={!!formErrors.rented_amount}
                      helperText={formErrors.rented_amount}
                    />
                    <Error current_key="rented_amount" />
                  </Grid>
                )}

                {formData?.is_rented && (
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      id="security_amount"
                      label="Security Amount"
                      name="security_amount"
                      type="number"
                      size="small"
                      inputProps={{
                        min: 1,
                      }}
                      autoComplete="security_amount"
                      value={formData.security_amount}
                      onChange={handleChange}
                      error={!!formErrors.security_amount}
                      helperText={formErrors.security_amount}
                    />
                    <Error current_key="security_amount" />
                  </Grid>
                )}
                {formData?.is_loanable && (
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      required
                      id="financer_name"
                      label="Financer Name"
                      name="financer_name"
                      type="string"
                      size="small"
                      inputProps={{
                        min: 1,
                      }}
                      autoComplete="financer_name"
                      value={formData.financer_name}
                      onChange={handleChange}
                      error={!!formErrors.financer_name}
                      helperText={formErrors.financer_name}
                    />
                    <Error current_key="financer_name" />
                  </Grid>
                )}
                {formData?.is_loanable && (
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      required
                      id="loan_duration"
                      label="Loan Duration"
                      name="loan_duration"
                      type="number"
                      size="small"
                      inputProps={{
                        min: 1,
                      }}
                      autoComplete="loan_duration"
                      value={formData.loan_duration}
                      onChange={handleChange}
                      error={!!formErrors.loan_duration}
                      helperText={formErrors.loan_duration}
                    />
                    <Error current_key="loan_duration" />
                  </Grid>
                )}
                {formData?.is_loanable && (
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      required
                      id="monthly_installment"
                      label="Monthly Installment"
                      name="monthly_installment"
                      type="number"
                      size="small"
                      inputProps={{
                        min: 1,
                      }}
                      autoComplete="monthly_installment"
                      value={formData.monthly_installment}
                      onChange={handleChange}
                      error={!!formErrors.monthly_installment}
                      helperText={formErrors.monthly_installment}
                    />
                    <Error current_key="monthly_installment" />
                  </Grid>
                )}
                <ReusableAddEditMachineInfo
                  machineType={formData.machine_id}
                  formData={formData}
                  handleChange={handleChange}
                  formErrors={formErrors}
                />

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="save-btn"
                    onSubmit={handleSubmit}
                    style={{ marginRight: "10px" }}
                    disabled={Object.values(formErrors).some(
                      (error) => !!error
                    )}>
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="close-btn"
                    onClick={() => handleModel(false)}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddEditMachineDetail;
