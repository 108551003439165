import React, { FC, useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Modal,
  Backdrop,
  Fade,
  Typography,
  Divider,
  Container,
  Alert,
  Autocomplete,
} from "@mui/material";
import {
  setError,
  useGetMachineWorkLogByIdQuery,
  useGetUserListByIdQuery,
  useCreateMachineWorkLogMutation,
  useEditMachineWorkLogMutation,
  useGetMachineNamesForMachineLogQuery,
  useMachineTripWorkItemQuery,
  useMachineTypeListQuery,
} from "../../../redux-services";
import { useDispatch } from "react-redux";
import Error from "../../../components/ErrorBox/Error";
import { useParams } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MachineDetail from "../../MachineInfo/ViewMachineInfo/MachineDetail";
export interface UserState {
  siteName?: string;
  employee_id: any;
  machine_id: any;
  work_item_id: any;
  measurement_value: number | any;
  from_reading: number | any;
  to_reading: number | any;
  measurement: string;
  number_plate: string;
}
interface FormErrors {
  measurement_value?: number | any;
  from_reading?: number | any;
  to_reading?: number | any;
}
const AddEditMachineWorkLog: FC<{
  open: boolean;
  handleModel: any;
  id: string;
  WorkItemId: string;
  refetch: any;
}> = ({ open, handleModel, id, WorkItemId, refetch }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };

  const { id: siteId } = useParams();

  const intialState = {
    siteName: "",
    employee_id: "",
    machine_id: "",
    work_item_id: WorkItemId ?? "",
    measurement_value: "",
    from_reading: "",
    to_reading: "",
    measurement: "",
    number_plate: "",
  };
  let dispatch = useDispatch();
  const [formData, setFormData] = useState<UserState>(intialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [showError, setShowError] = useState(false);
  const { data: MachineType } = useMachineTypeListQuery({});
  console.log(MachineType, "type");
  const [createMachineWorkLog, result] = useCreateMachineWorkLogMutation();
  const [editMachineWorkLog, editResult] = useEditMachineWorkLogMutation();

  // const { data: machineTrips } = useMachineTripWorkItemQuery(
  //   {
  //     work_item_id: WorkItemId,
  //     number_plate: formData.number_plate,
  //   },
  //   { skip: formData?.number_plate === "" }
  // );

  const organization_id = localStorage.getItem("orgId");
  const UserList = useGetUserListByIdQuery(
    { site_id: siteId, work_item_id: WorkItemId },
    { skip: !siteId || !WorkItemId }
  );

  const { data: MachineryData, refetch: refetchReading } =
    useGetMachineNamesForMachineLogQuery({
      site_id: siteId,
      work_item_id: WorkItemId,
      number_plate: formData.machine_id.number_plate,
      org_id: organization_id,
    });

  let new_machinery_data = MachineryData?.response?.filter((item: any) => {
    if (item?.from_reading >= 0) return item;
  });

  const [fromReadingState, setFromReadingState] = useState(
    formData.from_reading
  );

  const { data: MachineWorkLog } = useGetMachineWorkLogByIdQuery(id, {
    skip: !id,
  });
  useEffect(() => {
    if (MachineType) {
      console.log(MachineType, "machineType");
    }
  }, [MachineType]);
  useEffect(() => {
    if (MachineWorkLog?.status === 200) {
      let data = MachineWorkLog?.response;
      setFormData({
        ...formData,
        employee_id: data.userData?.length > 0 ? data.userData[0] : "",
        machine_id: data.machineData?.length > 0 ? data?.machineData[0] : "",
        work_item_id: data.work_item_id ?? "",
        measurement_value: data.measurement_value ?? "",
        measurement: data.measurement ?? "",
        from_reading: Number(data.from_reading ?? 0),
        to_reading: Number(data.to_reading ?? 0),
      });
    }
    return () => {
      dispatch(setError([]));
    };
  }, [MachineWorkLog]);

  useEffect(() => {
    if (result.isSuccess && result.data.status === 200) {
      handleModel(false);
      refetch();
    }
    if (result?.isSuccess && result?.data?.response === false) {
      setShowError(true);
    }
  }, [result]);

  useEffect(() => {
    if (editResult.isSuccess && editResult.data.status === 200) {
      handleModel(false);
      refetch();
    }
  }, [editResult]);

  const validate = (formData: UserState) => {
    let errors: Partial<UserState> = {};
    if (Number(formData.from_reading) > Number(formData.to_reading)) {
      errors.from_reading = "From reading should be less than To reading";
      errors.to_reading = "To reading should be greater than From reading";
    }
    if (formData.from_reading <= 0 || formData.from_reading === "") {
      errors.from_reading = "From Reading is required!";
    }
    if (formData.to_reading <= 0 || formData.to_reading === "") {
      errors.to_reading = "To  Reading is required!";
    }
    if (formData.measurement_value <= 0 || formData.measurement_value === "") {
      errors.measurement_value = "Measurment Value is required!";
    }

    return errors;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const checkError = validate(formData);
    if (Object.values(checkError)?.length > 0) {
      setFormErrors(checkError);
    } else {
      setFormErrors({});
      dispatch(setError([]));

      let formattedData = {
        ...formData,
        machine_id: formData.machine_id?._id ?? "",
        employee_id: formData.employee_id?._id ?? "",
        measurement_value: Number(formData.measurement_value),
        site_id: siteId,
        id: id ? id : null,
      };
      id
        ? editMachineWorkLog(formattedData)
        : createMachineWorkLog(formattedData);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>
  ) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value };
      if (name === "from_reading") {
        if (
          updatedFormData.measurement !== "trip" &&
          Number(updatedFormData.to_reading) > Number(value)
        ) {
          updatedFormData.measurement_value =
            Number(updatedFormData.to_reading) - Number(value);
        } else {
          updatedFormData.measurement_value = 0;
        }
      } else if (name === "to_reading") {
        if (
          updatedFormData.measurement !== "trip" &&
          Number(value) > Number(updatedFormData.from_reading)
        ) {
          updatedFormData.measurement_value =
            Number(value) - Number(updatedFormData.from_reading);
        } else {
          updatedFormData.measurement_value = 0;
        }
      }

      const errors = validate(updatedFormData);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        from_reading: errors.from_reading,
        to_reading: errors.to_reading,
        [name]: errors[name as keyof UserState],
      }));
      console.log(updatedFormData, "datsss");
      return updatedFormData;
    });
  };

  const selectUserChange = (event: SelectChangeEvent | any) => {
    setFormData({ ...formData, employee_id: event.target.value as string });
  };

  const fromReadingUpdated = useRef(false);

  useEffect(() => {
    if (formData.number_plate && new_machinery_data.length > 0) {
      const newFromReading = new_machinery_data[0]?.from_reading ?? 0;

      if (
        newFromReading !== formData.from_reading &&
        !fromReadingUpdated.current
      ) {
        fromReadingUpdated.current = true;

        setFormData((prevState) => ({
          ...prevState,
          from_reading: newFromReading,
        }));
      }
    }
  }, [formData.number_plate, new_machinery_data]);

  useEffect(() => {
    if (fromReadingUpdated.current && formData?.from_reading) {
      refetchReading();
      fromReadingUpdated.current = false;
    }
  }, [formData?.from_reading]);

  const selectMachineryChange = (
    event: SelectChangeEvent | any,
    newValue: any
  ) => {
    let data = newValue;
    formData.number_plate = data.number_plate;
    let findMachineType = MachineType?.response?.data?.find(
      (item: any) => item?._id === data?.machineTypeId
    );

    if (!findMachineType?.measurement_unit) {
      setFormData({
        ...formData,
        machine_id: data,
        measurement: findMachineType?.measurement_unit ?? "",
        number_plate: data?.number_plate ?? "",
        from_reading: new_machinery_data[0]?.from_reading ?? 0,
      });
    } else {
      setFormData({
        ...formData,
        machine_id: data,
        measurement: findMachineType?.measurement_unit ?? "",
        measurement_value: "",
        from_reading: "",
        to_reading: "",
      });
    }
  };

  return (
    <Container maxWidth={false} className="p-0">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Fade in={open}>
          <Box sx={style} className="form-outers karuModal">
            <Typography
              id="transition-modal-title"
              variant="h5"
              className="inner-headings"
              component="h2">
              {id ? "Edit Machine Work Log" : "Add Machine Work Log"}
            </Typography>
            <HighlightOffIcon
              className="closeicon"
              onClick={() => handleModel(false)}
            />
            <Box className="modalBody">
              {showError && (
                <Alert
                  variant="filled"
                  severity="error"
                  sx={{ marginTop: 2 }}
                  onClose={() => setShowError(false)}>
                  {result?.data?.message}
                </Alert>
              )}
              <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          options={MachineryData?.response || []}
                          getOptionLabel={(option: any) =>
                            `${option.machine_name}${
                              option.number_plate !== ""
                                ? ` (${option.number_plate})`
                                : ""
                            }`
                          }
                          value={formData.machine_id || null}
                          onChange={(event, newValue) =>
                            selectMachineryChange(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Machinery Names"
                              required
                              size="small"
                            />
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value?._id
                          }
                        />
                      </FormControl>
                      <Error current_key="machine_id" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Employee Names *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.employee_id}
                          label="Employee Name"
                          required
                          onChange={selectUserChange}
                          renderValue={() =>
                            formData.employee_id?.firstname +
                            ` ${formData.employee_id?.lastname} (${
                              formData.employee_id?.ccode +
                              "-" +
                              formData.employee_id?.contact_no
                            })`
                          }>
                          {UserList &&
                            UserList?.data?.response.map(
                              (item: any, index: number) => (
                                <MenuItem
                                  value={item}
                                  key={index}
                                  selected={
                                    formData?.employee_id?._id == item?._id
                                      ? true
                                      : false
                                  }>
                                  {item?.firstname +
                                    ` ${item?.lastname} (${
                                      item?.ccode + "-" + item?.contact_no
                                    })`}
                                </MenuItem>
                              )
                            )}
                        </Select>
                        {!id &&
                          UserList &&
                          UserList?.data?.response?.length === 0 && (
                            <Typography variant="body1" className="error-alert">
                              Today Employee Attendance is not Marked. First
                              Mark the Attendance.
                            </Typography>
                          )}
                      </FormControl>
                      <Error current_key="employee_id" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="from_reading"
                      label={`${
                        formData?.measurement === "hours"
                          ? "Previous Hour"
                          : "From Reading"
                      }`}
                      name="from_reading"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      type="number"
                      autoComplete="from_reading"
                      value={formData.from_reading}
                      onChange={handleChange}
                      error={!!formErrors.from_reading}
                      helperText={formErrors.from_reading}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="to_reading"
                      label={`${
                        formData?.measurement === "hours"
                          ? "Current Hour"
                          : "To Reading"
                      }`}
                      name="to_reading"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      type="number"
                      autoComplete="to_reading"
                      value={formData.to_reading}
                      onChange={handleChange}
                      error={!!formErrors.to_reading}
                      helperText={formErrors.to_reading}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ display: "flex" }}>
                    <TextField
                      fullWidth
                      id="measurement_value"
                      label="Measurement Value"
                      name="measurement_value"
                      size="small"
                      type="number"
                      required
                      autoComplete="measurement_value"
                      value={formData.measurement_value}
                      style={{ width: "90%", color: "white" }}
                      onChange={handleChange}
                      error={!!formErrors.measurement_value}
                      helperText={formErrors.measurement_value}
                    />
                    <TextField
                      fullWidth
                      id="measurement"
                      label=""
                      name="measurement"
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      autoComplete="measurement"
                      value={formData.measurement.toLocaleLowerCase()}
                      style={{ width: "10%", color: "#8f8888" }}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="save-btn"
                    disabled={Object.values(formErrors).some(
                      (error) => !!error
                    )}
                    style={{ marginRight: "10px", marginTop: "20px" }}>
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="close-btn"
                    onClick={() => handleModel(false)}
                    style={{ marginTop: "20px" }}>
                    Cancel
                  </Button>
                </Grid>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default AddEditMachineWorkLog;
