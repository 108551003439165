import React, { useMemo, useState } from "react";
import {
  Paper,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  debounce,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../../types/CommonTypes";
import {
  useDeleteFuelEntriesMutation,
  useMachineFuelEntriesListQuery,
} from "../../../redux-services";
import { SearchElement } from "../../../components/Search/Search";
import { TableHeadElement } from "../../../components/TableBody/TableHeadElement";
import { TableBodyElement } from "../../../components/TableBody/TableBodyElement";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "user_name",
    sort: true,
    label: "User Name",
  },
  {
    id: "machine_name",
    sort: true,
    label: "Machinery Name",
  },
  {
    id: "site_name",
    sort: true,
    label: "Site Name",
  },
  {
    id: "fuel_quantity",
    sort: false,
    label: "Fuel Quantity",
  },
  {
    id: "fuel_receipt_number",
    sort: true,
    label: "Receipt Number",
  },
  {
    id: "createdAt",
    sort: true,
    label: "Date",
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const FuelEntry = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  let navigate = useNavigate();
  const { id } = useParams();
  let query = useLocation();
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [fuelList, setFuelList] = useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");
  const [siteId, setSiteId] = useState<string>("");
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });

  const { data } = useMachineFuelEntriesListQuery({
    per_page: fuelList.per_page ?? rowsPerPage,
    page: query.state ?? fuelList.page,
    order: fuelList.order ?? order,
    order_by: fuelList.order_by ?? orderBy,
    searchText: fuelList.searchText ?? search,
    startDate: dateRange.startDate ? dateRange.startDate : "",
    endDate: dateRange.endDate ? dateRange.endDate : "",
    id: id ?? "",
    site_id: siteId ?? "",
  });

  const [deleteShiftLogs, response] = useDeleteFuelEntriesMutation();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setFuelList({
      ...fuelList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setFuelList({ ...fuelList, page: newPage + 1 });

    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setFuelList({
      ...fuelList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setFuelList({
        page: 1,
        per_page: fuelList.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, []);

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteShiftLogs(Id);
    } else {
      setIsClose(res);
    }
  };

  return (
    <div>
      <Paper className="form-outers">
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 40%" }}
            variant="h5"
            id="tableTitle"
            className="inner-headings"
            component="div">
            Fuel Entries List
          </Typography>
          <div className="flex-boxinner">
            <Typography sx={{ flex: "1 1 auto" }}>
              <SearchElement
                searchFn={searchHandler}
                searchTag={"Search Fuel Entries..."}
              />
            </Typography>
          </div>
        </Toolbar>
        <StyledEngineProvider>
          <TableContainer component={Paper} className="form-outers">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              className="table-outers">
              <TableHead>
                <TableRow>
                  <TableHeadElement
                    setHead={tableColumns ? tableColumns : []}
                    order={order}
                    orderBy={orderBy}
                    sortFn={(event, id: string) =>
                      createSortHandler(event, id as keyof SortingData)
                    }
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableBodyElement
                  selectedColumns={[
                    "user_name",
                    "machine_name",
                    "site_name",
                    "fuel_quantity",
                    "fuel_receipt_number",
                    "createdAt",
                  ]}
                  setData={data?.response.data ? data?.response.data : []}
                  pageData={{
                    limit:
                      rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: data?.response.page as number,
                  }}
                />
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={data?.response.total ?? 0}
              page={data?.response.page ?? 0}
              rowsPerPageOptions={[20, 50, 100]}
              onPageChange={handleChangePage}
              rowsPerPage={
                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </StyledEngineProvider>
      </Paper>
      <ConfirmBox
        title="Fuel Entry Delete Confirmation"
        message="Are you sure, you want to delete this Fuel Entry?"
        open={isClose}
        onClose={confirmBoxClose}
      />
    </div>
  );
};

export default FuelEntry;
