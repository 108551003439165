import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setError,
  useGetMachineryDetailQuery,
  useCreateAccessoriesMutation,
  useEditAccessoriesMutation,
  useGetAccessoriesByIdQuery,
} from "../../redux-services";
import Error from "../../components/ErrorBox/Error";
import ClearIcon from "@mui/icons-material/Clear";
import { Image } from "@mui/icons-material";

export interface UserState {
  accessories_name: string;
  machine_id: any;
  billing_date: string;
  warranty_till_date: string;
  amount: any;
  images: any;
  deleted_images?: any;
  image_url: any[];
  urls?: any[];
  used_till_date: string;
}

const AddEditAccessories = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const intialState = {
    accessories_name: "",
    machine_id: "",
    billing_date: "",
    warranty_till_date: "",
    amount: "",
    images: [],
    deleted_images: [],
    image_url: [],
    urls: [],
    used_till_date: "",
  };
  let dispatch = useDispatch();
  const [formData, setFormData] = useState<UserState>(intialState);
  const [createAccessories, result] = useCreateAccessoriesMutation();
  const [editAccessories, editResult] = useEditAccessoriesMutation();
  const { data: AccessoriesData } = useGetAccessoriesByIdQuery(id, {
    skip: !id,
  });
  const MachineryData = useGetMachineryDetailQuery();

  useEffect(() => {
    if (AccessoriesData?.status === 200) {
      let data: any = AccessoriesData?.response;
      setFormData({
        ...formData,
        accessories_name: data?.accessories_name ?? "",
        machine_id: data?.machineData[0] ?? "",
        billing_date: data?.billing_date ?? "",
        used_till_date: data?.used_till_date ?? "",
        warranty_till_date: data?.warranty_till_date ?? "",
        amount: data?.amount ?? "",
        images: data?.images?.length
          ? data.images.map((image: any) => image.path)
          : [],
        image_url: data?.encodedUrl?.length ? data?.encodedUrl : [],
        urls: data?.images?.length
          ? data?.images.map((image: any) => image.fullUrl)
          : [],
      });
    }
    return () => {
      dispatch(setError([]));
    };
  }, [AccessoriesData]);

  if (result.isSuccess && result.data.status === 200) {
    navigate("/admin/accessories");
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/admin/accessories");
  }

  const selectMachineryChange = (event: any) => {
    setFormData({ ...formData, machine_id: event.target.value as string });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(setError([]));
    let data = new FormData();
    for (const item of formData.images) {
      data.append("images", item);
    }
    if (id) {
      for (const item of formData.deleted_images) {
        data.append("deleted_images", item);
      }
    }
    data.append("accessories_name", formData.accessories_name.trim());
    data.append("machine_id", formData.machine_id?._id);
    data.append("billing_date", formData.billing_date);
    data.append("warranty_till_date", formData.warranty_till_date);
    data.append("amount", formData.amount);
    data.append("used_till_date", formData.used_till_date);
    data.append("id", id ?? (null as any));

    id ? editAccessories(data) : createAccessories(data);
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.trimStart() });
  };

  const handleChangeImage = (event: any) => {
    let setFileUrl: string[] = [];
    let file = Array.from(event.target.files);
    for (const element of event.target.files) {
      setFileUrl.push(URL.createObjectURL(element));
    }
    setFormData({
      ...formData,
      images: [...formData.images, ...file],
      image_url: [...formData.image_url, ...setFileUrl],
    });
    const thumbnailElement = document.getElementById(
      "images"
    ) as HTMLInputElement | null;

    if (thumbnailElement) {
      thumbnailElement.value = "";
    }
  };

  const handleRemoveImage = useCallback(
    (index: number) => {
      const updatedImages = [...formData.images];
      updatedImages.splice(index, 1);

      let deletedImgArr = [];
      if (formData.urls?.length) {
        deletedImgArr.push(formData.urls[index]);
      }

      const updatedFiles = [...formData.image_url];
      updatedFiles.splice(index, 1);
      setFormData({
        ...formData,
        images: updatedImages,
        image_url: updatedFiles,
        deleted_images: [...formData.deleted_images, ...deletedImgArr],
      });
    },
    [formData]
  );

  return (
    <Container maxWidth={false} className="p-0">
      <Card elevation={3} className="form-outers">
        <CardHeader
          className="inner-headings"
          title={id ? "Edit Accessories" : "Add Accessories"}
        />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  id="accessories_name"
                  label="Accessory Name"
                  name="accessories_name"
                  type="text"
                  size="small"
                  autoComplete="accessories_name"
                  value={formData.accessories_name}
                  onChange={handleChange}
                />
                <Error current_key="accessories_name" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Machinery Names *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.machine_id}
                      label="Machinery Name"
                      required
                      onChange={selectMachineryChange}
                      renderValue={() =>
                        formData.machine_id?.machine_type +
                        ` (${formData.machine_id?.number_plate})`
                      }
                    >
                      {MachineryData?.data?.response?.map((item: any) => (
                        <MenuItem value={item} key={item}>
                          {item?.machine_type + ` (${item?.number_plate})`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="billing_date"
                  label="Billing Date"
                  className="calendradate"
                  name="billing_date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  required
                  autoComplete="billing_date"
                  value={formData.billing_date}
                  onChange={handleChange}
                />
                <Error current_key="billing_date" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="warranty_till_date"
                  label="Warranty Till Date"
                  className="calendradate"
                  name="warranty_till_date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  required
                  autoComplete="warranty_till_date"
                  value={formData.warranty_till_date}
                  onChange={handleChange}
                />
                <Error current_key="warranty_till_date" />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="amount"
                  label="Amount"
                  name="amount"
                  size="small"
                  required
                  type="number"
                  value={formData.amount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="images"
                    name="images"
                    label="Accessories Images"
                    className="accessory_img"
                    type="file"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    inputProps={{ accept: "image/*", multiple: true }}
                    autoComplete="images"
                    onChange={handleChangeImage}
                  />
                  <Error current_key="images" />
                </Grid>
                <Grid item xs={12} className="imageDiv">
                  {formData?.image_url?.length
                    ? formData.image_url.map((image: string, index: number) => (
                      <div key={image}>
                        <img
                          src={image}
                          alt={`accessories-img-${index}`}
                          className="preview-img"
                        />
                        <Image
                          onClick={(event) => {
                            event.stopPropagation();
                            handleRemoveImage(index);
                          }} title="Remove Image"
                          component={"img"}
                        >
                          <ClearIcon />
                        </Image>
                      </div>
                    ))
                    : ""}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="save-btn"
                  onSubmit={handleSubmit}
                  style={{ marginRight: "10px" }}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  className="close-btn"
                  color="secondary"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AddEditAccessories;
