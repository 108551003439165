import { Box, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import React, { useCallback, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import arrowImage from "../../../assests/img/arrow.svg";
import SiteDetails from "./SiteDetails";
import SiteMachineInfo from "./SiteMachinery/indes";
import SiteUserList from "./SiteEmployees";
import FuelStorage from "./FuelStorage";
import { useGetSitesByIdQuery } from "../../../redux-services";
import BoreArea from "./BoreArea";
import WorkItem from "./WorkItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import MonthlyReport from "./MonthlyReport";
import TrackVehicleMovement from "../../VehicleMovement";
import BackButtonListener from "../../../components/BackButton/BackButton";
import TakeAttendance from "./SiteEmployees/TakeAttendance";
import Shift from "../../Shift";
import CompletedSiteMachine from "../CompletedSiteMachine";
import CompletedSiteEmployee from "../CompletedSiteEmployee";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

let CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`sites-tabpanel-${index}`}
      aria-labelledby={`sites-tab-${index}`}
      {...other}
      className="DarkTabpanel">
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

let a11yProps = (index: number) => {
  return {
    id: `sites-tab-${index}`,
    "aria-controls": `sites-tabpanel-${index}`,
  };
};

const ViewSites: React.FC = () => {
  const { id } = useParams<string>();
  const [value, setValue] = useState<number>(
    localStorage.getItem("selectedTab")
      ? parseInt(localStorage.getItem("selectedTab") || "0", 10)
      : 0
  );

  const [siteDetail, setSiteDetail] = useState({ id: "", name: "" });
  const [orgDetail, setOrgDetail] = useState({
    organizationId: "",
    organizationName: "",
  });
  const userDetail = useSelector((state: RootState) => state.auth.user);
  const [isDeactivate, setIsDeactivate] = useState(false);

  const { data: getData } = useGetSitesByIdQuery(id);

  useEffect(() => {
    if (getData?.status === 200) {
      let data = getData?.response;
      setSiteDetail({ id: data._id, name: data.name });
      setOrgDetail({
        organizationId: data?.organization_id[0]?._id,
        organizationName: data?.organization_id[0]?.organization_name,
      });
      setIsDeactivate(data.is_deactivate);
    }
  }, [getData]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      localStorage.setItem("selectedTab", newValue.toString());
    },
    []
  );

  const breadcrumbs = [
    ...(userDetail?.role_type === 0
      ? [{ name: "Organizations", to: "/admin/organization" }]
      : []),
    {
      name: orgDetail.organizationName,
      to: `/admin/organization/${orgDetail.organizationId}`,
    },
    { name: siteDetail.name, to: `/admin/organization/sites/${siteDetail.id}` },
  ];

  return (
    <>
      <BackButtonListener />
      <Box sx={{ flexGrow: 1 }} className="mt-3">
        <Toolbar>
          <div
            className="breadcrumbs"
            style={{ display: "flex", alignItems: "center" }}>
            {breadcrumbs.map((crumb, index) => (
              <React.Fragment key={index}>
                {" "}
                {/* Ensure unique key here */}
                {index > 0 && (
                  <img
                    src={arrowImage}
                    alt="arrow"
                    style={{ margin: "0 8px", verticalAlign: "middle" }}
                  />
                )}
                {index === breadcrumbs.length - 1 ? (
                  <Typography
                    variant="body2"
                    component="span"
                    style={{ color: "white" }}>
                    {crumb.name}
                  </Typography>
                ) : (
                  <Link
                    to={crumb.to}
                    className="breadcrumb-link"
                    style={{ color: "white" }}>
                    {crumb.name}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </div>
        </Toolbar>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "900",
            color: "#ADD8E6",
            marginTop: 1,
            marginLeft: 2,
          }}>
          {siteDetail.name}
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="Site Details" {...a11yProps(0)} />
            <Tab label="Machinery" {...a11yProps(1)} />
            <Tab label="Employees" {...a11yProps(2)} />
            <Tab label="Employee Attendance" {...a11yProps(3)} />
            <Tab label="Fuel Storage" {...a11yProps(4)} />
            <Tab label="Bore Area" {...a11yProps(5)} />
            <Tab label="Work Items" {...a11yProps(6)} />
            <Tab label="Shifts" {...a11yProps(7)} />
            <Tab label="Monthly Report" {...a11yProps(8)} />
            <Tab label="Replay Vehicle" {...a11yProps(9)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {typeof id !== "undefined" && (
            <SiteDetails
              org_id={orgDetail?.organizationId}
              id={id}
              setSiteDetail={setSiteDetail}
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box>
            {isDeactivate ? (
              <CompletedSiteMachine siteDetail={siteDetail} />
            ) : (
              <SiteMachineInfo siteDetail={siteDetail} />
            )}
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box>
            {isDeactivate ? (
              <CompletedSiteEmployee siteDetail={siteDetail} />
            ) : (
              <SiteUserList siteDetail={siteDetail} />
            )}
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Box>
            <TakeAttendance siteInfo={siteDetail} />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <FuelStorage />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <BoreArea siteDetail={siteDetail} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <WorkItem siteDetail={siteDetail} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          <Box>
            <Shift siteDetail={siteDetail} />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          <MonthlyReport siteDetail={siteDetail} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={9}>
          <TrackVehicleMovement siteDetail={siteDetail} />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default ViewSites;
